<script>
    import { intakeDataStore } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import * as accountTypes from '../../helpers/account_types';

    const type = 'risks';
    export let show;

    const categories = [...accountTypes.riskCategories];
    $: risks = $intakeDataStore[type]
</script>

<div class="{show ? 'collapse show' : 'collapse'}" aria-labelledby="Risks" data-parent="#risks">
    {#each risks as risk}
        <div class="panel">
            {#each categories as category, index}
                {#if risk.risk_type.name === categories[index].name}
                    <h3 class="page-title">{category.value}</h3>
                {/if}
            {/each}
            <div class="group">
                <div class="field split half-width">
                    <label>
                        <input 
                            type=radio 
                            bind:group={risk.possess} 
                            value={true}>
                        Yes, client has
                    </label>
                    <label>
                        <input 
                            type=radio 
                            bind:group={risk.possess} 
                            value={false}>
                        No, client does not have
                    </label>
                </div>
                <div class="field split half-width last">
                    <textarea
                        rows="5"
                        placeholder="Description"
                        bind:value="{risk.description}"></textarea>
                </div>
            </div>
        </div>
    {/each}
</div>

<style lang="stylus" scoped>label {
  cursor: pointer;
}
</style>
