<script>
    import accounting from '../../shared/accounting_config';
    import calculations from '../../helpers/calculations';
    import { intakeDataStore, planGeneratorStore } from '../../shared/store';
    import Wizard from '../../classes/Wizard/Wizard';

    let futureMonthlyGoalSavings = 0;
    let futureRetirementSavingsPerMonth = 0;
    let activeGoals = [];

    const { browser } = window.navigator.browserInfo;
    const appleOS = navigator.platform.toLowerCase().includes('mac' || 'ipo' || 'pike');

    $: wizard = new Wizard({ ...$intakeDataStore});

    // Current
    $: currentMonthlyNetIncome = accounting.unformat(accounting.toFixed(wizard.getIncome('net', 'monthly'), 0));
    $: currentMonthlyGrossIncome = accounting.unformat(accounting.toFixed(wizard.getIncome('gross', 'monthly'), 0));
    $: currentMonthlyFixedExpenses = accounting.unformat(accounting.toFixed(wizard.expenseManager.getTotalMonthAmount('fixed'), 0));
    $: currentMonthlyVariableExpenses = accounting.unformat(accounting.toFixed(wizard.expenseManager.getTotalMonthAmount('variable'), 0));
    $: currentMonthlyLiabilityPayments = accounting.unformat(accounting.toFixed(wizard.liabilityManager.totalMinimumPayment, 0));
    $: currentSavingsContributions = accounting.unformat(accounting.toFixed(
        wizard.getAssets([
            'savings',
            'trad_ira',
            'roth_ira',
            'sep',
            'solo_401k',
            'simple_ira',
            'taxable',
            '529',
            'coverdell_esa',
            'crypto',
            'family_trust'
            ], 'month', false, false)
        , 0));
    $: currentMonthlyGoalSavings = accounting.unformat(accounting.toFixed(
            wizard.goalManager.activeGoals.reduce((total, goal) => {
                return total + parseFloat(goal.getMonthAmount());
            }, 0)
        , 0));
    $: currentLeftOver = (
        currentMonthlyNetIncome - currentMonthlyFixedExpenses -
        currentMonthlyVariableExpenses - currentMonthlyLiabilityPayments -
        currentSavingsContributions - currentMonthlyGoalSavings
    );

    // Current total expenses + liabilites
    $: currentDebtMinimums = accounting.unformat(accounting.toFixed(wizard.liabilityManager.totalMinimumPayment, 0));

    // Future
    $: futureMonthlyNetIncome = currentMonthlyNetIncome;
    $: futureMonthlyFixedExpenses = currentMonthlyFixedExpenses;

    $: futureMonthlyLiabilityPayments = accounting.unformat(accounting.toFixed(
            wizard.liabilityManager.liabilities.reduce((total, liability) => {
                if (!liability.deferred) {
                    return total + (liability.minimumPayment ? parseFloat(liability.minimumPayment) : 0);
                }
                return total;
            }, 0)
            + ($planGeneratorStore.plan.student_loan_management_federal_amount ? parseFloat($planGeneratorStore.plan.student_loan_management_federal_amount) : 0)
            + ($planGeneratorStore.plan.student_loan_management_private_amount ? parseFloat($planGeneratorStore.plan.student_loan_management_private_amount) : 0)
            + ($planGeneratorStore.plan.debt_crushing_extra ? parseFloat($planGeneratorStore.plan.debt_crushing_extra) : 0)
        , 0));

    $: futureEmergencyFundSavingsPerMonth = accounting.unformat(accounting.toFixed(
            $planGeneratorStore.plan.emergency_fund_savings_per_month ?
            parseFloat($planGeneratorStore.plan.emergency_fund_savings_per_month) : 0
        , 0));

    $: futureRetirementSavingsPerMonth = accounting.unformat(
        accounting.toFixed(
            calculations.getRetirementSavings(
                $planGeneratorStore.plan.target_401k_contribution,
                $planGeneratorStore.plan.target_other_contribution,
                $planGeneratorStore.plan.target_ira_contribution,
                $planGeneratorStore.plan.target_roth_contribution,
                $planGeneratorStore.plan.retirement_current_contribution_percentage,
                $planGeneratorStore.plan.retirement_current_salary,
                $planGeneratorStore.plan.retirement_recommended_salary
            )
        )
    );


    $: if ($intakeDataStore.goals) {
        activeGoals = wizard.goalManager.activeGoals;
        futureMonthlyGoalSavings = 0;

        activeGoals.forEach(goal => {
            futureMonthlyGoalSavings += accounting.unformat(accounting.toFixed(
                (goal.planMonthAmount ? parseFloat(goal.planMonthAmount) : 0)
            , 0));

            if (!goal.showInPlanSpendingGoals) {
                futureMonthlyGoalSavings -= accounting.unformat(accounting.toFixed(
                    (goal.planMonthAmount ? parseFloat(goal.planMonthAmount) : 0)
                , 0));
            }

        });
    }

    $: futureLeftOver = (
        futureMonthlyNetIncome - futureMonthlyFixedExpenses -
        futureMonthlyLiabilityPayments - futureEmergencyFundSavingsPerMonth -
        futureRetirementSavingsPerMonth - futureMonthlyGoalSavings
    );
</script>

<div class='panel'>
    <h3 class='panel-title'>Current Monthly Budget</h3>
    <div class="client-birthday">
        <div class="container">
            <div class="bday-info">
                <h4>Net Income</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentMonthlyNetIncome)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Fixed Expenses</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentMonthlyFixedExpenses)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Variable Expenses</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentMonthlyVariableExpenses)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Liability Payments</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentMonthlyLiabilityPayments)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Savings Contribution</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentSavingsContributions)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Goal Savings</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(currentMonthlyGoalSavings)}</h4>
            </div>
        </div>
        <hr>
        <div class="container">
            <div class="bday-info">
                <h4>Total Left Over</h4>
            </div>
            <div class="send-email">
                <h4 class="{currentLeftOver < 0 ? 'negative' : ''}">{accounting.formatMoney(currentLeftOver)}</h4>
            </div>
        </div>
    </div>
</div>

<div class='panel'>
    <h3 class='panel-title'>Spending Goal</h3>
    <div class="client-birthday">
        <div class="container">
            <div class="bday-info">
                <h4>Net Income</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureMonthlyNetIncome)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Fixed Expenses</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureMonthlyFixedExpenses)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Liability Payments</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureMonthlyLiabilityPayments)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Emergency Fund Contribution</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureEmergencyFundSavingsPerMonth)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Retirement Contribution</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureRetirementSavingsPerMonth)}</h4>
            </div>
        </div>
        <div class="container">
            <div class="bday-info">
                <h4>Goal Savings</h4>
            </div>
            <div class="send-email">
                <h4>{accounting.formatMoney(futureMonthlyGoalSavings)}</h4>
            </div>
        </div>
        <hr>
        <div class="container">
            <div class="bday-info">
                <h4>Left Over for Variable Expenses</h4>
            </div>
            <div class="send-email">
                <h4 class="{futureLeftOver < 0 ? 'negative' : ''}">{accounting.formatMoney(futureLeftOver)}</h4>
            </div>
        </div>
    </div>
</div>

<div class='panel'>
    <div class="container">
        {#if browser !== 'firefox' || appleOS}
            <h5>
                Please press <strong>"{appleOS ? '⌘cmd' : 'ctrl'} + p"</strong> to print or save as PDF
            </h5>
        {/if}
        <h5>Please be sure to do the following:</h5>
        <ul>
            {#if browser === 'chrome' || browser === 'edge'}
                <li><h5>Click on <strong>"More Settings"</strong></h5></li>
                <li><h5>Uncheck the <strong>"Headers and footers"</strong> box</h5></li>
                <li><h5>Check the <strong>"Background graphics"</strong> box</h5></li>
                <li><h5>If saving to <strong>PDF</strong>: </h5>
                    <ul>
                        <li><h5>Set Destination to <strong>"Save as PDF"</strong></h5></li>
                        {#if appleOS}
                            <li><h5>Do <strong>NOT</strong> click on <strong>"Open PDF in Preview"</strong></h5></li>
                        {/if}
                    </ul>
                </li>
            {:else if browser === 'firefox'}
                {#if appleOS}
                    <li><h5>Click on <strong>"Show Details"</strong></h5></li>
                    <li><h5>Under "Appearance:" Check the <strong>"Print Background Colors"</strong> and <strong>"Print Background Images"</strong> boxes</h5></li>
                    <li><h5>Set all "Page Headers:" and "Page Footers:" to <strong>"--blank--"</strong></h5></li>
                    <li><h5>If saving to <strong>PDF</strong>: </h5>
                        <ul>
                            <li><h5>Click on <strong>PDF</strong> and select <strong>"Save as PDF"</strong></h5></li>
                            <li><h5>Under "Select Printer" select <strong>"Microsoft Print to PDF"</strong></h5></li>
                        </ul>
                    </li>
                {:else}
                    <li><h5>Click on menu button in top right corner, then click <strong>"Print"</strong></h5></li>
                    <li><h5>In this Print Preview mode, click <strong>"Page Setup"</strong></h5>
                        <ul>
                            <li><h5>Check box <strong>Print Background (colors & images)</strong></h5></li>
                            <li><h5>Under <strong>"Margins & Header/Footer"</strong>, set all header and foot options to <strong>"--blank--"</strong></h5></li>
                        </ul>
                    </li>
                    <li><h5>Click <strong>Print</strong></h5></li>
                    <li><h5>If saving to <strong>PDF</strong>: </h5>
                        <ul>
                            <li><h5>Under "Select Printer" select <strong>"Microsoft Print to PDF"</strong></h5></li>
                        </ul>
                    </li>

                {/if}
            {:else if browser === 'safari'}
                <li><h5>Click on <strong>"Show Details"</strong></h5></li>
                <li><h5>Check the <strong>"Print backgrounds"</strong> box</h5></li>
                <li><h5>Uncheck the <strong>"Print headers and footers"</strong> box</h5></li>
                <li><h5>If saving to <strong>PDF</strong>: </h5>
                    <ul>
                        <li><h5>Click on <strong>PDF</strong> on bottom left corner and select <strong>"Save as PDF"</strong></h5></li>
                    </ul>
                </li>
            {/if}
        </ul>
    </div>
</div>

<style lang="stylus" scoped>.container .bday-info {
  padding: 0;
}
.container .send-email {
  text-align: right;
}
.negative {
  color: var(--color-red);
}
@media screen and (max-width: 1024px) {
  .panel {
    display: none;
  }
}
</style>
