<script>
    export let type;
</script>

<h1 class="page-title">{type} Credit Score</h1>

<p>
    <i>
        This page contains affiliate links from partners who may compensate us. The Financial Gym may earn a commission on products that you purchase or apply for through these links.
    The compensation The Financial Gym receives may impact how and where links appear on this page. However, opinions expressed here are The Financial Gym's alone,
    not those of any bank or credit card. To learn more about why The Financial Gym participates in affiliate programs, read <a href="https://financialgym.com/blog/2021/7/12/message-from-the-ceo-never-wanted-to-sell-products">this message</a> from our CEO.
    </i>
</p>

<p>
    Your credit score is essentially your adult report card that shows lenders that you have the
    ability to borrow money and pay it back on-time.
</p>
<p>The biggest factors in calculating your score is the payment history and credit utilization.</p>
<ul>
    <li>Payment History = Did you make your minimum payment on-time?</li>
    <li>
        Credit Utilization = The amount owed compared to the overall credit limit at any given time.
        (i.e. if you have a $1,000 credit limit on a card, your balance should be no more than $350)
    </li>
    <li>Best practice is to pay your statement balance in full each month.</li>
</ul>

{#if type === 'establish'}
    <p>Here are some tips to help you build up your credit score:</p>
    <ul class="establish-credit-score-ul">
        <li>Step 1: Apply for a <a href="https://www.creditcards.com/affiliates/financial-gyms-building-credit/?aid=da1d7b19&tid=434fb2f4007944888249c9adb2cf227f" target="_blank">secured credit card</a>.</li>
        <li>
            Step 2: Only use the card for monthly recurring expenses like your $15 subscription,
            then set it to autopay each month. That way, you lower your risk of missing the payment
            and it ensures you have low utilization.
        </li>
        <li>
            Other non-traditional credit boosters:
            <ul>
                <li>
                    <a
                        href="https://www.experian.com/consumer-products/score-boost.html?bcd=ad_c_sem_427_379833196275&k_id=_k_EAIaIQobChMI39OJj7yn5wIVg5OzCh2sVAhxEAAYAiAAEgJnN_D_BwE_k_&k_kw=kwd-323755235674&k_mt=p&pc=sem_exp_google&cc=sem_exp_google_ad_1710090651_64683014057_379833196275_kwd-323755235674_p_1t2__k_EAIaIQobChMI39OJj7yn5wIVg5OzCh2sVAhxEAAYAiAAEgJnN_D_BwE_k_&ref=boostnonbrand&awsearchcpc=1&gclid=EAIaIQobChMI39OJj7yn5wIVg5OzCh2sVAhxEAAYAiAAEgJnN_D_BwE"
                        target="_blank">
                        Experian Boost
                    </a>
                    - Experian will report your on-time cell phone and utility bill payments towards
                    your credit for free just by signing up for the service. Average users boost
                    their FICO score by 13 points.
                </li>
                <li>
                    <a href="https://www.fico.com/ultrafico/" target="_blank">UltraFICO</a>
                    - Sign up for the waiting list for this service to evaluate your healthy banking
                    activity to boost your credit score.
                </li>
                <li>
                    <a href="https://www.renttrack.com/" target="_blank">RentTrack</a>
                    - For ~$10 a month, RentTrack will report on-time rent payments to the credit
                    bureaus to boost your credit score.
                </li>
            </ul>
        </li>
        <li>
            It is also a good idea to check your full credit report from one bureau once every 4
            months to verify there hasn’t been any suspicious activity or fraud on your account. You
            can do so for free here:
            <a href="https://www.annualcreditreport.com/index.action" target="_blank">
                https://www.annualcreditreport.com/index.action
            </a>
        </li>
    </ul>
{/if}

{#if type === 'maximize'}
    <p>
        Having a great credit score means you can take advantage of great perks in the form of
        travel or cash back rewards! While it may seem counterintuitive, having more credit cards
        can actually help to improve your score and help you save money by reducing the cost of
        travel and/or providing cash rewards that can be used to reduce bills or provide you with
        additional spending money!
    </p>
    <ul>
        <li>
            <a href="https://oc.brcclx.com/t/?lid=26680860&cr=30094&last_updated=1628704621" target="_blank">
            Travel Rewards Cards
            </a>
        </li>
        <li>
            <a href="https://oc.brcclx.com/t/?lid=26680861&cr=30095&last_updated=1628704660" target="_blank">
            Cash Back Cards
            </a>
        </li>
        <li>
            <a href="https://oc.brcclx.com/t/?lid=26680206&cr=19685&last_updated=1474321632" target="_blank">
            Get matched now
            </a>
        </li>
        <li>
            <a href="http://bilt-rewards.sjv.io/m5jzzX" target="_blank">
            Bilt Mastercard®
            </a> - this <a href="https://www.wellsfargo.com/credit-cards/bilt/terms/">no annual fee</a> credit card lets you earn Bilt Points when paying rent (up to 100,000 points in a calendar year) with no transaction fee. Note that you need to use the card 5 times each statement period to earn any points. You can use the points toward a credit on your next rent statement, a future home down payment, travel, and more.
        </li>

    </ul>
{/if}

<style lang="stylus" scoped>a:last-child {
  word-break: break-word;
}
</style>