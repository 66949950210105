<script>
    import { afterUpdate } from 'svelte';

    import { intakeDataStore } from '../../../shared/store';
    import * as api from '../../../shared/api_access';
    import * as time_frequencies from '../../../helpers/time_frequencies';
    import * as domFunctions from '../../../helpers/dom_functions';

    import FeatherIcon from '../../UXElements/FeatherIcon.svelte';

    export let type;

    const types = 'expenses';
    let expenses = [];

    const addExpense = () => {
        $intakeDataStore[types].push({
            name: '',
            amount: 0,
            frequency: 'monthly',
            expense_type: {
                name: type
            },
            edited: false
        });

        expenses = $intakeDataStore[types].filter(expense => {
            return expense.expense_type.name === type;
        });
    };

    const removeExpense = async (expense_id, index) => {
        expenses.splice(index, 1);

        const newArray = $intakeDataStore[types].filter(expense => {
            return expense.expense_type.name !== type;
        });

        $intakeDataStore[types] = [...newArray, ...expenses];

        if (expense_id) {
            await api.remove(expense_id, types);
        }
    };

    $: {
        // Populate expenses with example inputs for that expense type if empty

        // This can't be handeled like earlier with $intakeDataStore['expenses'].length === 0
        // because this is run twice, once for fixed and once for variable. If fixed enters values
        // the array wont be empty so the logic wont fire for variable and no variable will be entered
        if ($intakeDataStore[types].filter(expense => expense.expense_type.name === type).length === 0) {

            const fixedExpensePlaceholders = [
                'Rent',
                'Utilities',
                'Financial Gym'
            ]
            const variableExpensePlaceholders = [
                'Groceries',
                'Eating Out',
                'Clothes'
            ]
            const expensePlaceholders = type === 'fixed' ? fixedExpensePlaceholders : variableExpensePlaceholders;

            // Push expense placeholders onto the $intakeDataStore expenses
            expensePlaceholders.forEach(expensePlaceholder => {
                $intakeDataStore[types].push({
                    name: '',
                    amount: 0,
                    frequency: 'monthly',
                    expense_type: {
                        name: type
                    },
                    // This new field does not get saved but is used for
                    // displaying a non saving suggestion for the expense name
                    placeholder: expensePlaceholder,
                    edited: false
                });
            });
        }

        expenses = $intakeDataStore[types].filter(expense => expense.expense_type.name === type);
    }

    afterUpdate(() => {
        // prevent non-numeric texts to be typed
        domFunctions.inputNumbersOnly();
    });
</script>

<div class="flex-wrapper main-wrapper container">
    <div class="panel">
        <h3 class="page-title">{type}</h3>

        <div class="group">
            <div class="field split third-width">
                <label for="{type}-expense-name">Name</label>
            </div>

            <div class="field split third-width">
                <label for="{type}-expense-amount">Amount</label>
            </div>

            <div class="field split third-width last">
                <label for="{type}-expense-frequency">Frequency</label>
            </div>
        </div>

        {#each expenses as expense, index}
            <div class="group">
                <div class="field split third-width">
                    <input
                        id="{type}-expense-name-{index}"
                        class="form-control"
                        type="text"
                        placeholder="{'placeholder' in expense ? expense.placeholder : 'Name'}"
                        bind:value="{expense.name}"
                        on:input="{'edited' in expense ? expense.edited = true : null}"
                        data-cy="{type}-expense-name-{expense.id}"
                    />
                </div>

                <div class="field split third-width">
                    <input
                        id="{type}-expense-amount-{index}"
                        class="form-control"
                        type="number"
                        step="0.01"
                        placeholder="Amount"
                        bind:value="{expense.amount}"
                        on:input="{'edited' in expense ? expense.edited = true : null}"
                        data-cy="{type}-expense-amount-{expense.id}"
                    />
                </div>

                <div class="field split third-width last">
                    <select id="{type}-expense-frequency-{index}" class="form-control" bind:value="{expense.frequency}"
                    on:input="{'edited' in expense ? expense.edited = true : null}">
                        {#each time_frequencies.timeFrequencies as choice}
                            <option value="{choice.value}">{choice.name}</option>
                        {/each}
                    </select>
                    <button
                        class="alert button icon-button"
                        title="Remove this {type} expense"
                        aria-label="Remove this {type} expense"
                        on:click|preventDefault="{async () => removeExpense(expense.id, index)}"
                        data-cy="expense-remove-{expense.id}">
                        <FeatherIcon icon="x" />
                    </button>
                </div>
            </div>
        {/each}


    </div>
</div>
<button class="button small primary margin-bottom" on:click="{() => addExpense(type)}" title="Add">
    <!-- Title might be a lighthouse check thing? else not needed -->
    Add {type} Expense
</button>


<style lang="stylus" scoped>h3,
button {
  text-transform: capitalize;
}
label {
  padding-left: 1em;
}
.alert {
  color: var(--color-orange);
}
.third-width {
  margin-right: 2%;
  width: 32%;
}
.third-width.last {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 10fr 1fr;
  margin-right: 0;
}
.third-width.last label {
  grid-column: 1/span 2;
}
.third-width.last a {
  align-self: center;
}
.main-wrapper .panel {
  height: 360px;
  width: 100%;
}
.margin-bottom {
  margin-bottom: 1em;
}
</style>
