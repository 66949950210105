<script>
    import { onMount } from 'svelte';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    import * as domFunctions from '../../../helpers/dom_functions';

    export let label = null;
    export let message;
    export let inputType = null;
    export let value = null;
    export let selectOptions = null;
    export let index = null;

    let required;

    onMount(() => {
        // prevent non-numeric texts to be typed
        domFunctions.inputNumbersOnly();
    });

    $: {
        switch (label) {
            case 'balance':
                label = 'Current Balance';
                break;
            case 'interest':
                label += '_(%)';
                break;
            case 'percent_owned':
                label += '_(%)';
                break;
            case 'min_payment':
                label += '_*';
                required = true;
                break;
            case 'previous':
                label = 'From Previous Employer?'
                break;
            case 'contribution':
                label = 'Monthly Contribution'
                break;
            case 'employer contribution':
                label = 'Monthly Employer Contribution'
                break;
            case 'paid_in_full':
                label = 'This card is paid in full each month';
                break;
            default:
                required = false;
                break;
        }
    }
</script>

<div class="field {inputType === 'boolean' ? 'checkboxes' : ''}">
    {#if inputType !== 'boolean'}
        <label for='{label}-{index}'>{label.split('_').join(' ')}</label>
        {#if message !== undefined}
            <small class="text-muted">
                {message}
            </small>
        {/if}
    {/if}
    {#if inputType === 'text'}
        <input id='{label}-{index}' class="form-control" type="text" placeholder="" bind:value />
    {:else if inputType === 'number'}
        <NumberInput id='{label}-{index}' bind:value={value} {required}/>
    {:else if inputType === 'boolean'}
        <label class="check-label">
            <input class="form-control" type="checkbox" bind:checked="{value}" />
            <span>{label.split('_').join(' ')}</span>
        </label>
    {:else if inputType === 'select'}
        <select id='{label}-{index}' class="form-control" bind:value>
            <option value=''>
                Please Select A{['a','e ','i','o','u'].includes(label[0]) ? 'n' : ''} {label.split('_').join(' ')}
            </option>
            {#each selectOptions as choice}
                <option value="{choice.value}">{choice.name}</option>
            {/each}
        </select>
    {/if}
</div>

<style lang="stylus" scoped>label {
  text-transform: capitalize;
}
label.pointer {
  cursor: pointer;
}
select {
  text-transform: capitalize;
}
.text-muted {
  color: var(--color-gray-medium);
}
</style>
