<script>
    import { onMount, afterUpdate } from 'svelte';
    import { location, link } from 'svelte-spa-router';

    import { planGeneratorStore, intakeDataStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';
    import calculations from '../../helpers/calculations';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import DebtMinimumsTable from './DebtMinimumsTable.svelte';
    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;
    export let clickOnSpendingEdit;
    export let page;

    // Duplicate Page Vars
    let duplicateMounted = false;

    let planURL = $location;
    let activeGoals = [];
    let activeTotalMonth = 0;
    let spendingGoalsTotal = 0;
    let remainderMonthlySpend = 0;
    let emergencyFund = 0;
    let retirementSavings = 0;
    let retirementSavingsDisplay = 0;
    let debtCrushingExtra = 0;
    let federalLoan = 0;
    let privateLoan = 0;
    let minDebtPayments;

    const lifeMoneyGoals = ['car_purchase', 'family_planning', 'moving', 'pet_care', 'travel_savings', 'wedding', 'grad_school'];
    const notInPageGoals = ['investing', 'general_savings', 'other'];

    $: currentFixedExpenses = wizard.getExpenses('fixed', 'monthly');
    $: monthlyNetIncome = wizard.getIncome('net', 'monthly');
    $: creditScore = wizard.getCreditScore();
    $: totalCreditCardDebt = wizard.getLiabilities(['charge_card', 'credit_card'], 'all');

    $: if($intakeDataStore.goals) {
        activeGoals = wizard.goalManager.activeGoals;
        activeTotalMonth = 0;

        activeGoals.forEach(goal => {
            activeTotalMonth += (Number.isFinite(parseFloat(goal.planMonthAmount)) ? parseFloat(goal.planMonthAmount) : 0);

            if (!goal.showInPlanSpendingGoals) {
                activeTotalMonth -= (Number.isFinite(parseFloat(goal.planMonthAmount)) ? parseFloat(goal.planMonthAmount) : 0);
            }

            goal.slug = goal.type.name.split('_').join('-');
        });
    }

    $: {

        emergencyFund = ($planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month ? ($planGeneratorStore.plan.emergency_fund_savings_per_month ? parseFloat($planGeneratorStore.plan.emergency_fund_savings_per_month) : 0) : 0);

        retirementSavingsDisplay = accounting.unformat(
                accounting.toFixed(
                    calculations.getRetirementSavings(
                        $planGeneratorStore.plan.target_401k_contribution,
                        $planGeneratorStore.plan.target_other_contribution,
                        $planGeneratorStore.plan.target_ira_contribution,
                        $planGeneratorStore.plan.target_roth_contribution,
                        $planGeneratorStore.plan.retirement_current_contribution_percentage,
                        $planGeneratorStore.plan.retirement_current_salary,
                        $planGeneratorStore.plan.retirement_recommended_salary
                    )
                )
            );

        if ($planGeneratorStore.plan.spending_goal_show_retirement_savings) {
            retirementSavings = retirementSavingsDisplay;
        } else {
            retirementSavings = 0;
        }

        debtCrushingExtra = ($planGeneratorStore.plan.spending_goal_show_debt_crushing_extra ? ($planGeneratorStore.plan.debt_crushing_extra ? parseFloat($planGeneratorStore.plan.debt_crushing_extra) : 0) : 0);

        federalLoan = ($planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount ? ($planGeneratorStore.plan.student_loan_management_federal_amount ? parseFloat($planGeneratorStore.plan.student_loan_management_federal_amount) : 0) : 0);

        privateLoan = ($planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount ? ($planGeneratorStore.plan.student_loan_management_private_amount ? parseFloat($planGeneratorStore.plan.student_loan_management_private_amount) : 0) : 0);


        spendingGoalsTotal = activeTotalMonth + emergencyFund + retirementSavings + debtCrushingExtra + federalLoan + privateLoan;

        remainderMonthlySpend = monthlyNetIncome - currentFixedExpenses - minDebtPayments - spendingGoalsTotal;

        if ($planGeneratorStore.plan.weekly_spend_goal === null) {
            $planGeneratorStore.plan.weekly_spend_goal = Math.round(remainderMonthlySpend / 4.3333, 2);
        }

    }

    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();

    const toggleGoalInSpendingGoals = (index) => {
        if(!page.duplicate) {
            activeGoals[index].showInPlanSpendingGoals = !activeGoals[index].showInPlanSpendingGoals;
            $intakeDataStore.goals[index].show_in_plan_spending_goals = !$intakeDataStore.goals[index].show_in_plan_spending_goals;
        } else {
            page.data.active_goals[index].showInPlanSpendingGoals = !page.data.active_goals[index].showInPlanSpendingGoals
        }

    }

    const togglePaymentGoalInSpendingGoals = (type) => {
        const showPayment = 'spending_goal_show_' + type;

        if (!page.duplicate) {
            $planGeneratorStore.plan[showPayment] = !$planGeneratorStore.plan[showPayment];
        } else {
            page.data[showPayment] = !page.data[showPayment];
        }
    }

    function calculateWeeklySpendGoal() {
        if(!page.duplicate) {
            $planGeneratorStore.plan.weekly_spend_goal = Math.round(remainderMonthlySpend / 4.3333, 2);
        } else {
            const remainderMonthlySpend = Number.isFinite(parseFloat(page.data.remainder_monthly_spend)) ? parseFloat(page.data.remainder_monthly_spend) : 0;

            page.data.weekly_spend_goal = Math.round(remainderMonthlySpend / 4.3333, 2);
        }
    }

    function calculateSpendingGoalTotal() {
        let activeTotalMonth = 0;
        page.data.active_goals.forEach(goal => {
            activeTotalMonth += (Number.isFinite(parseFloat(goal.planMonthAmount)) ? parseFloat(goal.planMonthAmount) : 0);

            if (!goal.showInPlanSpendingGoals) {
                activeTotalMonth -= (Number.isFinite(parseFloat(goal.planMonthAmount)) ? parseFloat(goal.planMonthAmount) : 0);
            }

            goal.slug = goal.type.name.split('_').join('-');
        });

        emergencyFund = (page.data.spending_goal_show_emergency_fund_savings_per_month ? (Number.isFinite(parseFloat(page.data.emergency_fund_savings_per_month)) ? parseFloat(page.data.emergency_fund_savings_per_month) : 0) : 0);

        retirementSavings = (page.data.spending_goal_show_retirement_savings ? (Number.isFinite(parseFloat(page.data.retirement_savings)) ? parseFloat(page.data.retirement_savings) : 0) : 0);

        debtCrushingExtra = (page.data.spending_goal_show_debt_crushing_extra ? (Number.isFinite(parseFloat(page.data.debt_crushing_extra)) ? parseFloat(page.data.debt_crushing_extra) : 0) : 0);

        federalLoan = (page.data.spending_goal_show_student_loan_management_federal_amount ? (Number.isFinite(parseFloat(page.data.student_loan_management_federal_amount)) ? parseFloat(page.data.student_loan_management_federal_amount) : 0) : 0);

        privateLoan = (page.data.spending_goal_show_student_loan_management_private_amount ? (Number.isFinite(parseFloat(page.data.student_loan_management_private_amount)) ? parseFloat(page.data.student_loan_management_private_amount) : 0) : 0);

        page.data.spending_goals_total = activeTotalMonth + emergencyFund + retirementSavings + debtCrushingExtra + federalLoan + privateLoan;

    }

    function calculateRemainderMonthlySpend () {
        page.data.remainder_monthly_spend =
            (Number.isFinite(parseFloat(page.data.monthly_net_income)) ? parseFloat(page.data.monthly_net_income) : 0) -
            (Number.isFinite(parseFloat(page.data.current_fixed_expenses)) ? parseFloat(page.data.current_fixed_expenses) : 0) -
            (Number.isFinite(parseFloat(page.data.credit_card)) ? parseFloat(page.data.credit_card) : 0) -
            (Number.isFinite(parseFloat(page.data.student_loan)) ? parseFloat(page.data.student_loan) : 0) -
            (Number.isFinite(parseFloat(page.data.other)) ? parseFloat(page.data.other) : 0) -
            (Number.isFinite(parseFloat(page.data.mortgage)) ? parseFloat(page.data.mortgage) : 0) -
            (Number.isFinite(parseFloat(page.data.spending_goals_total)) ? parseFloat(page.data.spending_goals_total) : 0)
    }

    $: if ($location.split('/')[3]) {
        planURL = `/${$location.split('/')[1]}/${$location.split('/')[2]}`;
    }

    $: if (page.duplicate) {
        if (!page.id) {
            if (page.data.length === 0) {
                page.data = {
                    name: `Spending Goal - ${parseInt(page.title.split('_')[1]) + 1}`,
                    monthly_net_income: parseFloat(monthlyNetIncome).toFixed(0),
                    current_fixed_expenses: parseFloat(currentFixedExpenses).toFixed(0),
                    spending_goals_total: parseFloat(spendingGoalsTotal).toFixed(0),
                    remainder_monthly_spend: parseFloat(remainderMonthlySpend).toFixed(0),
                    weekly_spend_goal: parseFloat($planGeneratorStore.plan.weekly_spend_goal).toFixed(0),
                    emergency_fund_savings_per_month: parseFloat($planGeneratorStore.plan.emergency_fund_savings_per_month).toFixed(0),
                    retirement_savings: parseFloat(retirementSavingsDisplay).toFixed(0),
                    debt_crushing_extra: parseFloat($planGeneratorStore.plan.debt_crushing_extra).toFixed(0),
                    student_loan_management_federal_amount: parseFloat($planGeneratorStore.plan.student_loan_management_federal_amount).toFixed(0),
                    student_loan_management_private_amount: parseFloat($planGeneratorStore.plan.student_loan_management_private_amount).toFixed(0),

                    active_goals: activeGoals.map(goal => {
                        const { showInPlanSpendingGoals, slug, type, name, planMonthAmount } = goal;
                        return {
                            name,
                            planMonthAmount,
                            showInPlanSpendingGoals,
                            slug,
                            type
                        }
                    }),

                    // toggle handlers
                    spending_goal_show_emergency_fund_savings_per_month: $planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month,
                    spending_goal_show_retirement_savings: $planGeneratorStore.plan.spending_goal_show_retirement_savings,
                    spending_goal_show_debt_crushing_extra: $planGeneratorStore.plan.spending_goal_show_debt_crushing_extra,
                    spending_goal_show_student_loan_management_federal_amount: $planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount,
                    spending_goal_show_student_loan_management_private_amount: $planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount
                };

                page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"Being on a budget may seem like a negative, but staying tuned in to your spending allows you to spend and save toward things you value, and cut out expenses that don't support your goals.\n"}]});

                page.data[`${page.title}_quill_text_1`] = $planGeneratorStore.plan.spending_goal_trainer_comments;
            }
        }
    }

    $: if (page.data) {
        calculateSpendingGoalTotal();
        calculateRemainderMonthlySpend();
    }

    onMount(() => {
        if (page.duplicate) {
            duplicateMounted = true;
        }
    });
</script>

<h1 class="page-title">
    {#if !page.duplicate}
        Spending Goal
    {:else}
        <div class="input-group hide-print">
            <input type="text" placeholder="Name" bind:value={page.data.name} />
        </div>
        <span class="show-print">{page.data.name}</span>
    {/if}
</h1>

{#if !page.duplicate}
    <p>
        Being on a budget may seem like a negative, but staying tuned in to your spending allows you to spend and save toward things you value, and cut out expenses that don't support your goals.
    </p>
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_0" />
{/if}

<table>
    <thead>
        <tr>
            <th colspan="2">Recommended Budget</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>MONTHLY NET INCOME</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(monthlyNetIncome)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.monthly_net_income} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.monthly_net_income)}</span>
                {/if}
            </td>
        </tr>
        <tr>
            <th>CURRENT FIXED EXPENSES</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(currentFixedExpenses)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.current_fixed_expenses} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.current_fixed_expenses)}</span>
                {/if}
            </td>
        </tr>

        <DebtMinimumsTable
            {duplicateMounted}
            {wizard}
            bind:minDebtPayments
            bind:page />

        <tr>
            <th>SAVINGS/DEBT REPAYMENT GOALS</th>
            <td>
                {#if !page.duplicate}
                    <strong>{accounting.formatMoney(spendingGoalsTotal)}</strong>
                {:else}
                    <strong>{accounting.formatMoney(page.data.spending_goals_total)}</strong>
                {/if}
            </td>
        </tr>

        <tr class="{(!page.duplicate ? !$planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month : !page.data.spending_goal_show_emergency_fund_savings_per_month) ? 'hide-goal hide-print' : ''}">
            <td>
                <div class="hide-print grid-row grid-icon">
                    <div>
                        <a
                            class="spending-goal-edit hide-print"
                            href="{planURL}/months-of-expenses-saved"
                            title="Go To Months Of Expenses Saved"
                            aria-label="Go To Months Of Expenses Saved"
                            on:click={() => clickOnSpendingEdit("months-of-expenses-saved")}
                            use:link>
                            <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                        </a>
                    </div>
                    <div>
                        Emergency Savings Goal
                    </div>
                </div>
                <div class="show-print sub-goal">
                    Emergency Savings Goal
                </div>
            </td>
            <td>
                {#if !page.duplicate}
                    <div class="grid-row grid-2 sub-goal-amount">
                        {accounting.formatMoney(parseFloat($planGeneratorStore.plan.emergency_fund_savings_per_month))}
                        <button
                            class="toggle-{$planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('emergency_fund_savings_per_month')}"
                            title="{$planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            aria-label="{$planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if $planGeneratorStore.plan.spending_goal_show_emergency_fund_savings_per_month}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                {:else}
                    <div class="grid-row grid-2 sub-goal-amount hide-print">
                        <NumberInput
                            type="dollar"
                            bind:value={page.data.emergency_fund_savings_per_month} />
                        <button
                            class="toggle-{page.data.spending_goal_show_emergency_fund_savings_per_month ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('emergency_fund_savings_per_month')}"
                            title="{page.data.spending_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            aria-label="{page.data.spending_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if page.data.spending_goal_show_emergency_fund_savings_per_month}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data.emergency_fund_savings_per_month)}
                    </span>
                {/if}
            </td>
        </tr>

        <tr class="{(!page.duplicate ? !$planGeneratorStore.plan.spending_goal_show_retirement_savings : !page.data.spending_goal_show_retirement_savings) ? 'hide-goal hide-print' : ''}">
            <td>
                <div class="hide-print grid-row grid-icon">
                    <div>
                        <a
                            class="spending-goal-edit hide-print"
                            href="{planURL}/retirement-planning"
                            title="Go To Retirement Planning slide"
                            aria-label="Go To Retirement Planning slide"
                            on:click={() => clickOnSpendingEdit("retirement-planning")}
                            use:link>
                            <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                        </a>
                    </div>
                    <div>
                        Retirement Savings
                    </div>
                </div>
                <div class="show-print sub-goal">
                    Retirement Savings
                </div>
            </td>
            <td>
                {#if !page.duplicate}
                    <div class="grid-row grid-2 sub-goal-amount">
                        {accounting.formatMoney(parseFloat(retirementSavingsDisplay))}
                        <button
                            class="toggle-{$planGeneratorStore.plan.spending_goal_show_retirement_savings ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('retirement_savings')}"
                            title="{$planGeneratorStore.plan.spending_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            aria-label="{$planGeneratorStore.plan.spending_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if $planGeneratorStore.plan.spending_goal_show_retirement_savings}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                {:else}
                    <div class="grid-row grid-2 sub-goal-amount hide-print">
                        <NumberInput
                            type="dollar"
                            bind:value={page.data.retirement_savings} />
                        <button
                            class="toggle-{page.data.spending_goal_show_retirement_savings ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('retirement_savings')}"
                            title="{page.data.spending_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            aria-label="{page.data.spending_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if page.data.spending_goal_show_retirement_savings}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data.retirement_savings)}
                    </span>
                {/if}
            </td>
        </tr>

        {#if !page.duplicate}
            {#each activeGoals as goal, index}
                {#if goal.type.name !== 'mindset'}
                    <tr class="{!goal.showInPlanSpendingGoals ? 'hide-goal hide-print' : ''}">
                        <td>
                            <div class="hide-print grid-row grid-icon">
                                <div>
                                    {#if (!notInPageGoals.includes(goal.type.name))}
                                        <a
                                            class="hide-print"
                                            href="{planURL}/{goal.slug}"
                                            title="Go To {goal.type.name.split('_').join(' ')}"
                                            aria-label="Go To {goal.type.name.split('_').join(' ')}"
                                            on:click={() => clickOnSpendingEdit(goal.slug)}
                                            use:link >
                                            <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                                        </a>
                                    {/if}
                                </div>
                                <div>
                                    {goal.name}
                                </div>
                            </div>
                            <div class="show-print sub-goal">
                                {goal.name}
                            </div>
                        </td>
                        <td>
                            <div class="hide-print grid-row grid-2 sub-goal-amount">
                                {#if lifeMoneyGoals.includes(goal.type.name)}
                                    {accounting.formatMoney(parseFloat(goal.planMonthAmount))}
                                {:else}
                                    <NumberInput
                                        type="dollar"
                                        bind:value={goal.planMonthAmount} />
                                {/if}

                                <button
                                    class="toggle-{goal.showInPlanSpendingGoals ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                    title="{goal.showInPlanSpendingGoals ? 'Hide Row' : 'Show Row'}"
                                    aria-label="{goal.showInPlanSpendingGoals ? 'Hide Row' : 'Show Row'}"
                                    on:click|preventDefault="{() => toggleGoalInSpendingGoals(index)}"
                                    >
                                    {#if goal.showInPlanSpendingGoals}
                                        <FeatherIcon icon="x" />
                                    {:else}
                                        <FeatherIcon icon="rotate-ccw" />
                                    {/if}
                                </button>
                            </div>
                            <div class="show-print sub-goal-amount">
                                {accounting.formatMoney(parseFloat(goal.planMonthAmount))}
                            </div>
                        </td>
                    </tr>
                {/if}
            {/each}
        {:else}
            {#each page.data.active_goals as goal, index}
                {#if goal.type.name !== 'mindset'}
                    <tr class="{!goal.showInPlanSpendingGoals ? 'hide-goal hide-print' : ''}">
                        <td>
                            <div class="hide-print grid-row grid-icon">
                                <div>
                                    {#if (!notInPageGoals.includes(goal.type.name))}
                                        <a
                                            class="hide-print"
                                            href="{planURL}/{goal.slug}"
                                            title="Go To {goal.type.name.split('_').join(' ')}"
                                            aria-label="Go To {goal.type.name.split('_').join(' ')}"
                                            on:click={() => clickOnSpendingEdit(goal.slug)}
                                            use:link >
                                            <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                                        </a>
                                    {/if}
                                </div>
                                <div>
                                    {goal.name}
                                </div>
                            </div>
                            <div class="show-print sub-goal">
                                {goal.name}
                            </div>
                        </td>
                        <td>
                            <div class="hide-print grid-row grid-2 sub-goal-amount">
                                <NumberInput
                                    type="dollar"
                                    bind:value={goal.planMonthAmount} />
                                <button
                                    class="toggle-{goal.showInPlanSpendingGoals ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                    title="{goal.showInPlanSpendingGoals ? 'Hide Row' : 'Show Row'}"
                                    aria-label="{goal.showInPlanSpendingGoals ? 'Hide Row' : 'Show Row'}"
                                    on:click|preventDefault="{() => toggleGoalInSpendingGoals(index)}"
                                    >
                                    {#if goal.showInPlanSpendingGoals}
                                        <FeatherIcon icon="x" />
                                    {:else}
                                        <FeatherIcon icon="rotate-ccw" />
                                    {/if}
                                </button>
                            </div>
                            <div class="show-print sub-goal-amount">
                                {accounting.formatMoney(parseFloat(goal.planMonthAmount))}
                            </div>
                        </td>
                    </tr>
                {/if}
            {/each}
        {/if}

        {#if totalCreditCardDebt > 0}
            <tr class="{(!page.duplicate ? !$planGeneratorStore.plan.spending_goal_show_debt_crushing_extra : !page.data.spending_goal_show_debt_crushing_extra) ? 'hide-goal hide-print' : ''}">
                <td>
                    <div class="hide-print grid-row grid-icon">
                        <div>
                            <a
                                class="spending-goal-edit hide-print"
                                href="{planURL}/debt-crushing-goal"
                                title="Go To Debt Crushing Goal"
                                aria-label="Go To Debt Crushing Goal"
                                on:click={() => clickOnSpendingEdit("debt-crushing-goal")}
                                use:link >
                                <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                            </a>
                        </div>
                        <div>
                            Additional Credit Card Payment
                        </div>
                    </div>
                    <div class="show-print sub-goal">
                        Additional Credit Card Payment
                    </div>
                </td>
                <td>
                    {#if !page.duplicate}
                        <div class="grid-row grid-2 sub-goal-amount">
                            {accounting.formatMoney(parseFloat($planGeneratorStore.plan.debt_crushing_extra))}
                            <button
                                class="toggle-{$planGeneratorStore.plan.spending_goal_show_debt_crushing_extra ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{$planGeneratorStore.plan.spending_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.spending_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('debt_crushing_extra')}" >
                                {#if $planGeneratorStore.plan.spending_goal_show_debt_crushing_extra}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    {:else}
                        <div class="grid-row grid-2 sub-goal-amount">
                            <NumberInput
                                type="dollar"
                                bind:value={page.data.debt_crushing_extra} />
                            <button
                                class="toggle-{page.data.spending_goal_show_debt_crushing_extra ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{page.data.spending_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                aria-label="{page.data.spending_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('debt_crushing_extra')}"
                                >
                                {#if page.data.spending_goal_show_debt_crushing_extra}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                        <span class="show-print">
                            {accounting.formatMoney(page.data.debt_crushing_extra)}
                        </span>
                    {/if}
                </td>
            </tr>
        {/if}

        {#if wizard.liabilityManager.getByStudentLoanType(['federal']).length > 0}
            <tr class="{(!page.duplicate ? !$planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount : !page.data.spending_goal_show_student_loan_management_federal_amount) ? 'hide-goal hide-print' : ''}">
                <td>
                    <div class="hide-print grid-row grid-icon">
                        <div>
                            <a
                                class="spending-goal-edit hide-print"
                                href="{planURL}/student-loan-management"
                                title="Go To Student Loan Management"
                                aria-label="Go To Student Loan Management"
                                on:click={() => clickOnSpendingEdit("student-loan-management")}
                                use:link >
                                <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                            </a>
                        </div>
                        <div>
                            Additional Federal Student Loan Payment
                        </div>
                    </div>
                    <div class="show-print sub-goal">
                        Additional Federal Student Loan Payment
                    </div>
                </td>
                <td>
                    {#if !page.duplicate}
                        <div class="grid-row grid-2 sub-goal-amount">
                            {accounting.formatMoney(parseFloat($planGeneratorStore.plan.student_loan_management_federal_amount))}
                            <button
                                class="toggle-{$planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{$planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('student_loan_management_federal_amount')}"
                            >
                                {#if $planGeneratorStore.plan.spending_goal_show_student_loan_management_federal_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    {:else}
                        <div class="grid-row grid-2 sub-goal-amount">
                            <NumberInput
                                type="dollar"
                                bind:value={page.data.student_loan_management_federal_amount} />
                            <button
                                class="toggle-{page.data.spending_goal_show_student_loan_management_federal_amount ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{page.data.spending_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{page.data.spending_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('student_loan_management_federal_amount')}"
                                >
                                {#if page.data.spending_goal_show_student_loan_management_federal_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                        <span class="show-print">
                            {accounting.formatMoney(page.data.student_loan_management_federal_amount)}
                        </span>
                    {/if}
                </td>
            </tr>
        {/if}

        {#if wizard.liabilityManager.getByStudentLoanType(['private']).length > 0}

            <tr class="{(!page.duplicate ? !$planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount : !page.data.spending_goal_show_student_loan_management_private_amount) ? 'hide-goal hide-print' : ''}">
                <td>
                    <div class="hide-print grid-row grid-icon">
                        <div>
                            <a
                                class="spending-goal-edit hide-print"
                                href="{planURL}/student-loan-management"
                                title="Go To Student Loan Management"
                                aria-label="Go To Student Loan Management"
                                on:click={() => clickOnSpendingEdit("student-loan-management")}
                                use:link >
                                <FeatherIcon icon="edit" attrs="{{ width: '16px' }}" />
                            </a>
                        </div>
                        <div>
                            Additional Private Student Loan Payment
                        </div>
                    </div>
                    <div class="show-print sub-goal">
                        Additional Private Student Loan Payment
                    </div>
                </td>
                <td>
                    {#if !page.duplicate}
                        <div class="grid-row grid-2 sub-goal-amount">
                            {accounting.formatMoney(parseFloat($planGeneratorStore.plan.student_loan_management_private_amount))}
                            <button
                                class="toggle-{$planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{$planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('student_loan_management_private_amount')}"
                            >
                                {#if $planGeneratorStore.plan.spending_goal_show_student_loan_management_private_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    {:else}
                        <div class="grid-row grid-2 sub-goal-amount">
                            <NumberInput
                                type="dollar"
                                bind:value={page.data.student_loan_management_private_amount} />
                            <button
                                class="toggle-{page.data.spending_goal_show_student_loan_management_private_amount ? 'off' : 'on'} spending-goal-button hide-print button icon-button"
                                title="{page.data.spending_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{page.data.spending_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                on:click|preventDefault="{() => togglePaymentGoalInSpendingGoals('student_loan_management_private_amount')}"
                                >
                                {#if page.data.spending_goal_show_student_loan_management_private_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                        <span class="show-print">
                            {accounting.formatMoney(page.data.student_loan_management_private_amount)}
                        </span>
                    {/if}
                </td>
            </tr>
        {/if}

        <tr>
            <th>REMAINDER MONTHLY SPEND</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(remainderMonthlySpend)}
                {:else if duplicateMounted}
                    {accounting.formatMoney(page.data.remainder_monthly_spend)}
                {/if}
            </td>
        </tr>
        <tr>
            <th>WEEKLY SPEND GOALS</th>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print grid-row grid-2">
                        <NumberInput
                            type="dollar"
                            bind:value={$planGeneratorStore.plan.weekly_spend_goal} />
                        <div class="spending-goal-button-container-weekly">
                            <button
                                class="button small primary append spending-goal-button spending-goal-button-weekly"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Calculate Weekly Spend Goal based on Remainder Monthly Spend/4.3333"
                                on:click="{() => calculateWeeklySpendGoal()}">
                                Calculate
                            </button>
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney($planGeneratorStore.plan.weekly_spend_goal)}
                    </span>
                {:else}
                    <div class="hide-print grid-row grid-2">
                        <NumberInput
                            type="dollar"
                            bind:value={page.data.weekly_spend_goal} />
                        <div class="spending-goal-button-container-weekly">
                            <button
                                class="button small primary append spending-goal-button spending-goal-button-weekly"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Calculate Weekly Spend Goal based on Remainder Monthly Spend/4.3333"
                                on:click="{() => calculateWeeklySpendGoal()}">
                                Calculate
                            </button>
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data.weekly_spend_goal)}
                    </span>
                {/if}
            </td>
        </tr>
    </tbody>
</table>

{#if !page.duplicate}
    <TrainerCommentComponent type="{'spending_goal_trainer_comments'}" />
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_1" />
{/if}

<style lang="stylus" scoped>td {
  padding: 8px 20px;
  word-break: break-word;
}
td:last-child {
  word-break: unset;
}
td:nth-child(2) {
  width: 50%;
}
.grid-row {
  margin-bottom: 0;
}
.grid-row.grid-2 {
  grid-template-columns: 5fr 1fr;
}
.grid-row.grid-icon {
  grid-template-columns: 1fr 20fr;
}
.grid-row a {
  justify-self: right;
}
.hide-goal {
  opacity: 50%;
  background-color: #f5f5f5;
}
@media screen and (min-width: 481px) {
  .sub-goal-amount {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 480px) {
  table {
    display: block;
    overflow-x: auto;
  }
  .grid-row.grid-2 {
    justify-items: right;
    grid-template-columns: 1fr;
  }
  .grid-row.grid-icon {
    grid-template-columns: 1fr 5fr;
  }
  td {
    padding: 8px;
  }
}
@media screen and (min-width: 481px) and (max-width: 720px) {
  .grid-row.grid-icon {
    grid-template-columns: 1fr 10fr;
  }
}
@media print {
  td {
    padding: 8px;
  }
  .sub-goal {
    padding-left: 2rem;
  }
  .sub-goal-amount {
    padding-left: 1rem;
  }
}
</style>
