<script>
    import { link, location } from 'svelte-spa-router';
    import { loggedIn, trainerDataStore } from '../../shared/store';

    let show = true;

    $: show = window.screen.width < 1025 && $location !== '/' ? false : true;

    const handleResize = (event) => {
        if (window.screen.width > 1024) {
            show = true;
        } else {
            show = $location === '/';
        }
    };
</script>

<svelte:window on:resize="{ handleResize }" />

{#if $loggedIn}
    <!-- Clients Summary; Only show on mobile devices when on the dashboard. Always show on desktop -->
    <div class="client-summary" class:show="{ show }">
        <div class="panel container w90">
            <h3 class="clients-total">Clients</h3>
            <!--
            <h3 class="clients-total">Total Clients</h3>

            <div class="cgrowth-stats">
                <div class="clients-number" id="numClients">172</div>

                <div class="clients-growth">
                    <!--
                        Alternate (change displayed icon):
                        class="down"
                    -->
                    <!--
                    <span class="up" id="cgNum">5</span> clients growth<br/>
                    <small>(30 days)</small>
                </div>
            </div>
            -->

            <div>
                <!-- <a class="button small secondary" href="#">View All</a> -->
                <a
                    class="button small primary"
                    href="/new-client"
                    aria-label="Create new client"
                    use:link >
                    Create Client +
                </a>
            </div>
        </div>
    </div>
{/if}

<style lang="stylus" scoped>.client-summary {
  display: none;
}
.client-summary.show {
  display: block;
}
</style>
