<script>
    import Request from '../../classes/Request/Request';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import Search from '../UXElements/Search.svelte';


    let search;
    let institutions = [];
    let loadedInstitutions = false;
    let plaidStatusErrorMessage = null;

    const getInstitutionById = (index) => {
        institutions[index].loading = true;
        institutions = institutions;

        const requestClassProps = {
            type: 'post',
            url: '/get_institution_by_id', 
            apiSource: 'plaid',
            params: {
                institutionId: institutions[index].institution_id, 
                status: true
            }
        }

        requestClassProps.errorHandler = (errorResponse = undefined) => {
            if (errorResponse === undefined) {
                institutions[index].statusError = 'An unexpected error occurred. Please try again.';
                institutions = institutions;
                return;
            }
            
            institutions[index].statusError = `${errorResponse.data.error.error_code}: ${errorResponse.data.error.error_message}`;
            institutions[index].loading = false;
            institutions = institutions;
            return;
        }
        
        requestClassProps.successHandler = (successResponse) => {
            institutions[index].status = successResponse.data.institution.status;
            institutions[index].loading = false;
            institutions[index].statusError = null;
            institutions = institutions;
        }

        const getInstitutionByIdRequest = new Request(requestClassProps);

        getInstitutionByIdRequest.requestData();
    }

    const clearSearch = async () => {
        if (search !== '') {
            search = '';
            searchInstitutions();
        }
    };

    const searchInstitutions = async () => {
        plaidStatusErrorMessage = null;
        // Start loading animation
        loadedInstitutions = false;

        // Track old values for changes
        const previousSearch = search;

        // If this call is made due to a new input,
        // wait 500ms before making a request to ensure the user isn't still typing
        setTimeout(async () => {
            // Check if the user has made more changes to the search,
            // don't continue with the request if they have
            if (search !== previousSearch) {
                return;
            }

            if (search === '') {
                return;
            }
            
            const requestClassProps = {
                type: 'post',
                url: '/search_institutions', 
                apiSource: 'plaid',
                params: {
                    searchQuery: search
                }
            }

            requestClassProps.errorHandler = (errorResponse) => {
                if (errorResponse === undefined) {
                    plaidStatusErrorMessage = 'An unexpected error occurred. Please try again.';
                    return false;
                }

                plaidStatusErrorMessage = `${errorResponse.data.error.error_code}: ${errorResponse.data.error.error_message}`;

                return false;
            }

            requestClassProps.successHandler = (successResponse) => {
                if (search === previousSearch) {
                    institutions = successResponse.data.institutions;

                    const sortedData = institutions.sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });

                    institutions = sortedData;
                }
                return true;
            }

            const searchInstitutionsRequest = new Request(requestClassProps);

            loadedInstitutions = await searchInstitutionsRequest.requestData();
        }, 400);
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'healthy':
                return 'green';
            case 'degraded':
                return 'yellow';
            case 'down':
                return 'red';
            default:
                return 'orange';
        };
    }

    const getProductDisplayName = (product) => {
        switch (product) {
            case 'item_logins':
                return 'Logins';
            case 'transactions_updates':
                return 'Transactions';
            case 'balance':
                return 'Balance';
            default:
                return '';
        };
    }
</script>

<div class="plaid-status container">
    <h3 class="panel-title">Plaid Status</h3>

    <Search
        placeholder="Search for institution in Plaid..."
        bind:input="{search}"
        searchFn="{() => searchInstitutions()}"
        clearSearchFn="{async () => clearSearch()}"
        />

    <div class="panel">
        {#if plaidStatusErrorMessage !== null}
            <div class="alert message">{plaidStatusErrorMessage}</div>
        {:else if loadedInstitutions}
            <table class="plaid-status-table">
                <tbody>
                    {#if institutions.length === 0}
                        <div class="alert alert-info">No Results Found</div>
                    {:else}
                        <tr>
                            <td colspan="2">
                                <small class="gray-text">Click on an institution name to get or refresh status</small>
                            </td>
                        </tr>
                        {#each institutions as institution, index}
                            <tr
                                on:click="{() => {
                                    getInstitutionById(index);
                                }}">
                                <td class="logo-plaid">
                                    {#if institution.logo !== null}
                                        <img
                                            src="data:image/png;base64,{institution.logo}"
                                            alt="{institution.name}_logo" />
                                    {/if}
                                </td>
                                <td>
                                    <h4 class="institution-name">{institution.name}</h4>
                                    {#if institution.loading}
                                        <div class="spin spin-left">
                                            <FeatherIcon icon="aperture" />
                                        </div>
                                    {:else if institution.statusError}
                                        <div class="alert alert-info">
                                            {institution.statusError}
                                        </div>
                                    {:else if institution.status}
                                        {#if institution.status.item_logins || institution.status.transactions_updates || institution.status.balance}
                                            {#each Object.entries(institution.status) as product}
                                                {#if product[0] === 'item_logins' || product[0] === 'transactions_updates' || product[0] === 'balance'}
                                                    <div class="indicator grid-row">
                                                        <span
                                                            class="{getStatusIcon(product[1].status.toLowerCase())}"></span>
                                                        <h5>
                                                            {getProductDisplayName(product[0])}
                                                            : {product[1] !== null ? product[1].status : `Status Not Available`}
                                                        </h5>
                                                    </div>
                                                {/if}
                                            {/each}
                                            {#if !institution.status.item_logins}
                                                <div class="indicator grid-row">
                                                    <span class="orange"></span>
                                                    <h5>Logins: Status Not Available</h5>
                                                </div>
                                            {/if}
                                            {#if !institution.status.transactions_updates}
                                                <div class="indicator grid-row">
                                                    <span class="orange"></span>
                                                    <h5>Transactions: Status Not Available</h5>
                                                </div>
                                            {/if}
                                            {#if !institution.status.balance}
                                                <div class="indicator grid-row">
                                                    <span class="orange"></span>
                                                    <h5>Balance: Status Not Available</h5>
                                                </div>
                                            {/if}
                                        {:else}
                                            <div class="indicator grid-row">
                                                <span class="orange"></span>
                                                <h5>Information Not Available on Plaid</h5>
                                            </div>
                                        {/if}
                                    {/if}
                                </td>
                            </tr>
                        {/each}
                    {/if}
                </tbody>
            </table>
        {:else if search && plaidStatusErrorMessage === null}
            <div class="spin">
                <FeatherIcon icon="aperture" />
            </div>
        {:else}
            <div class="gray-text">
                Please enter a search term to find financial institutions in Plaid
            </div>
        {/if}
    </div>
</div>

<style lang="stylus" scoped>tr {
  cursor: pointer;
}
.gray-text {
  color: var(--color-gray-medium);
}
.institution-name {
  margin-bottom: 1em;
}
.grid-row {
  grid-template-columns: 1fr 25fr;
  margin-bottom: 0;
  grid-gap: 1em;
}
.indicator {
  width: auto;
}
.spin {
  text-align: center;
  display: block;
}
.spin-left {
  float: left;
}
.search {
  position: relative;
}
.search a {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
</style>
