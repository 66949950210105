<script>
    export let wizard;

    $: planGoals = wizard.goalManager.allGoals;
    $: planNonNegotiables = wizard.nonNegotiableManager.nonnegotiables;

</script>

<h1 class="page-title">What You’re Working For:</h1>

<h3>Short-Term Goals (1-3 Years):</h3>
<ol>
    {#each planGoals as goal}
        {#if goal.getGoalLength() === 'short'}
            <li>{goal.name}</li>
        {/if}
    {/each}
</ol>

<h3>Mid-Term Goals (3-5 Years):</h3>
<ol>
    {#each planGoals as goal}
        {#if goal.getGoalLength() === 'mid'}
            <li>{goal.name}</li>
        {/if}
    {/each}
</ol>

<h3>Long-Term Goals:</h3>
<ol>
    {#each planGoals as goal}
        {#if goal.getGoalLength() === 'long'}
            <li>{goal.name}</li>
        {/if}
    {/each}
</ol>

<h3>Most Important</h3>
<ol>
    {#each planNonNegotiables as nonNegotiable}
        <li>{nonNegotiable.name}</li>
    {/each}
</ol>

<style lang="stylus" scoped>ol {
  word-break: break-word;
}
</style>