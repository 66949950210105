<script>
    import { onMount, afterUpdate } from 'svelte';
    import dayjs from 'dayjs';
    import Chart from 'chart.js';

    import accounting from '../../shared/accounting_config';
    import { planGeneratorStore } from '../../shared/store';

    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;

    $: liabilities = wizard.liabilityManager.getLiabilities(
        ['charge_card', 'credit_card'],
        'apr',
        'dsc',
        null,
        true
    );

    // Payment info
    let minimumPayment = null;
    let debtCrushingExtra = null;
    let highestInterestDebtMonthlyPayment = null;

    // Rendering
    let chartMessage = '';
    let ctx = null;
    let hasMounted = false;
    let lineChart = null;
    let renderChart = true;

    onMount(() => {
        hasMounted = true;
    })
    afterUpdate(() => {
        ctx = document.getElementById('chart');
    });

    // Run this only if payment info changes
    $: if (
        hasMounted && (
            minimumPayment !== parseFloat(liabilities[0].minimumPayment) ||
            debtCrushingExtra !== $planGeneratorStore.plan.debt_crushing_extra
        )
    ) {
        // Calculate money going towards loan
        minimumPayment = parseFloat(liabilities[0].minimumPayment);
        minimumPayment = isNaN(minimumPayment) ? 0 : minimumPayment;

        debtCrushingExtra = parseFloat($planGeneratorStore.plan.debt_crushing_extra);
        debtCrushingExtra = isNaN(debtCrushingExtra) ? 0 : debtCrushingExtra;

        highestInterestDebtMonthlyPayment = minimumPayment + debtCrushingExtra;

        // Gather payment info about the liability
        const originalBalance = liabilities[0].balance;
        let payments = [];
        try {
            payments = liabilities[0].getPaidOffDateByPayment(highestInterestDebtMonthlyPayment);
            chartMessage = '';
        } catch (error) {
            chartMessage = error.message.replace('Error: ', '');
        }

        // Conditionaly render and build the chart based on number of points
        renderChart = payments.length > 2;
        ctx = document.getElementById('chart');
        if (chartMessage === '' && !renderChart) {
            chartMessage = 'Not enough payments during the life of the loan to show the chart.';
        }
        // Destroy old chart before building a new one
        if (lineChart !== null) {
            lineChart.destroy();
        }
        if (renderChart && ctx !== null) {
            lineChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: payments.map(payment => dayjs(payment.date).format('MMMM YYYY')),
                    datasets: [
                        {
                            label: 'Debt Reduction',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: '#00C980',
                            borderColor: '#00C980',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: '#ffffff',
                            pointBackgroundColor: '#D2FAED',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#D2FAED',
                            pointHoverBorderColor: '#ffffff',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: payments.map(payment => payment.balance),
                            spanGaps: false
                        }
                    ]
                }
            });
        }
    }

    // Run this to print page without creating a lot of blank pages
    setTimeout(() => {
        const element = document.getElementsByClassName("chartjs-size-monitor")[0];
        element.classList.add("hide-print");
    }, 1000);
</script>

<div id="debt-crushing-goal">
    <h1 class="page-title">Debt Crushing Goal</h1>
    <p>
        Debt management is a key part of any financial planning strategy. Remember, not all debt is
        bad! Sometimes, it is a necessary tool that we utilize to accomplish our goals or to help us
        through emergencies! That being said, we still need to begin actively tackling your debt in
        order to optimize your financial health and move forward with some of your other goals.
    </p>
    <p>
        Right now,
        <strong>{liabilities[0].name}</strong>
        is your biggest priority. I recommend you devote an extra
        <span class="input-group hide-print">
            <NumberInput
                type="dollar"
                bind:value="{$planGeneratorStore.plan.debt_crushing_extra}" />
        </span>
        <span class="show-print">
            {accounting.formatMoney($planGeneratorStore.plan.debt_crushing_extra)}
        </span>
        per month towards repaying this debt.
        {#if liabilities.length > 1}
            Once we have paid this down, we can move onto
            <strong>{liabilities[1].name}</strong>
            .
        {/if}
    </p>

    <p>Here’s what your current debt repayment strategy should look like:</p>

    <ol>
        {#each liabilities as liability, index}
            <li>
                {#if index === 0}
                    {liability.name} = {accounting.formatMoney(highestInterestDebtMonthlyPayment)}
                {/if}
                {#if index > 0 && liabilities[index] && index !== liabilities.length - 1}
                    {liability.name} = {accounting.formatMoney(liability.minimumPayment)}
                {/if}
                {#if index !== 0 && index === liabilities.length - 1}
                    {liability.name} = {accounting.formatMoney(liability.minimumPayment)}
                {/if}
            </li>
        {/each}
    </ol>

    {#if chartMessage !== ''}
        <div
            class="error alert alert-danger hide-print"
            on:click={() => (chartMessage = '')}>
            {chartMessage}
        </div>
    {/if}

    <canvas id="chart" width="400" height="400" class={renderChart ? '' : 'hide-canvas'} />
</div>

<style lang="stylus" scoped>canvas {
  max-width: 100%;
}
.hide-canvas {
  display: none;
}
p {
  margin-bottom: 10px !important;
}
.error {
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
