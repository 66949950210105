<script>
    import accounting from '../../../shared/accounting_config';

    import { planGeneratorStore } from '../../../shared/store';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let slide;
    export let wizard;
    export let currentBalance;
    export let previousBalance;
    export let otherBalance;
    export let preTaxBalance;
    export let postTaxBalance;
    export let page = {};
    export let duplicateMounted = false;

    let employerContributionPercentage = wizard.getEmployerContributionPercentage();
    let totalContribution = Math.round((wizard.getEmployerContributionPercentage() + wizard.getCurrentContributionPercentage()) * 100) / 100;
    let otherContributionPercentage = wizard.getOtherContributionPercentage();
    let preTaxContributionPercentage = wizard.getPreTaxContributionPercentage();
    let postTaxContributionPercentage = wizard.getPostTaxContributionPercentage();

    $: if (page.duplicate && !duplicateMounted) {
        if (!page.id) {
            page.data = {
                ...page.data,
                current_balance: currentBalance.toFixed(0),
                previous_balance: previousBalance.toFixed(0),
                other_balance: otherBalance.toFixed(0),
                pre_tax_balance: preTaxBalance.toFixed(0),
                post_tax_balance: postTaxBalance.toFixed(0),
                employer_contribution: parseFloat(wizard.getEmployerContributionPercentage()).toFixed(2)
            };

            if (slide === 'current') {
                page.data = {
                    ...page.data,
                    current_contribution_percentage: totalContribution.toFixed(2),
                    other_contribution_percentage: parseFloat(wizard.getOtherContributionPercentage()).toFixed(2),
                    pre_tax_contribution_percentage: parseFloat(wizard.getPreTaxContributionPercentage()).toFixed(2),
                    post_tax_contribution_percentage: parseFloat(wizard.getPostTaxContributionPercentage()).toFixed(2)
                };

            } else {
                page.data = {
                    ...page.data,
                    current_contribution_percentage: $planGeneratorStore.plan.target_401k_contribution ? parseFloat($planGeneratorStore.plan.target_401k_contribution).toFixed(2) : 0,
                    other_contribution_percentage: $planGeneratorStore.plan.target_other_contribution ? parseFloat($planGeneratorStore.plan.target_other_contribution).toFixed(2) : 0,
                    pre_tax_contribution_percentage: $planGeneratorStore.plan.target_ira_contribution ? parseFloat($planGeneratorStore.plan.target_ira_contribution).toFixed(2) : 0,
                    post_tax_contribution_percentage: $planGeneratorStore.plan.target_roth_contribution ? parseFloat($planGeneratorStore.plan.target_roth_contribution).toFixed(2) : 0
                };
            }
        }
    }
</script>

<table>
    <thead>
        <tr>
            <th></th>
            <th colspan="2">Employer Accounts</th>
            <th colspan="3">Individual Accounts</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td></td>
            <td class="text-muted">Current</td>
            <td class="text-muted">Previous</td>
            <td class="text-muted">Other</td>
            <td class="text-muted">Pre-Tax</td>
            <td class="text-muted">Post-Tax</td>
        </tr>
        <tr>
            <td>Balance</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(currentBalance)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.current_balance} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.current_balance)}</span>
                {/if}
            </td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(previousBalance)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.previous_balance} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.previous_balance)}</span>
                {/if}
            </td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(otherBalance)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.other_balance} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.other_balance)}</span>
                {/if}
            </td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(preTaxBalance)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.pre_tax_balance} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.pre_tax_balance)}</span>
                {/if}
            </td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(postTaxBalance)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.post_tax_balance} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.post_tax_balance)}</span>
                {/if}

            </td>
        </tr>
        <tr>
            <td>Employer Match</td>
            <td>
                {#if !page.duplicate}
                    {employerContributionPercentage}%
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="percent" bind:value={page.data.employer_contribution} />
                    </div>
                    <span class="show-print">{page.data.employer_contribution}%</span>
                {/if}
            </td>

            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Total Contribution</td>
            {#if slide === 'current'}
                <td>
                    {#if !page.duplicate}
                        {totalContribution}%
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput placeholder="0" type="percent" bind:value={page.data.current_contribution_percentage} />
                        </div>
                        <span class="show-print">{page.data.current_contribution_percentage}%</span>
                    {/if}
                </td>
                <td id="retirement-previous-contribution">
                    N/A
                </td>
                <td>
                    {#if !page.duplicate}
                        {otherContributionPercentage}%
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput placeholder="0" type="percent" bind:value={page.data.other_contribution_percentage} />
                        </div>
                        <span class="show-print">{page.data.other_contribution_percentage}%</span>
                    {/if}
                </td>
                <td>
                    {#if !page.duplicate}
                        {preTaxContributionPercentage}%
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput placeholder="0" type="percent" bind:value={page.data.pre_tax_contribution_percentage} />
                        </div>
                        <span class="show-print">{page.data.pre_tax_contribution_percentage}%</span>
                    {/if}
                </td>
                <td>
                    {#if !page.duplicate}
                        {postTaxContributionPercentage}%
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput placeholder="0" type="percent" bind:value={page.data.post_tax_contribution_percentage} />
                        </div>
                        <span class="show-print">{page.data.post_tax_contribution_percentage}%</span>
                    {/if}
                </td>
            {:else}
                <td>
                    {#if !page.duplicate}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Current Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={$planGeneratorStore.plan.target_401k_contribution} />
                        </div>
                        <span class="show-print">
                            {$planGeneratorStore.plan.target_401k_contribution ? `${$planGeneratorStore.plan.target_401k_contribution}%` : ''}
                        </span>
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Current Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={page.data.current_contribution_percentage} />
                        </div>
                        <span class="show-print">
                            {page.data.current_contribution_percentage ? `${page.data.current_contribution_percentage}%` : ''}
                        </span>
                    {/if}
                </td>
                <td>Rollover to IRA</td>
                <td>
                    {#if !page.duplicate}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Other Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={$planGeneratorStore.plan.target_other_contribution} />
                        </div>
                        <span class="show-print">
                            {$planGeneratorStore.plan.target_other_contribution ? `${$planGeneratorStore.plan.target_other_contribution}%` : ''}
                        </span>

                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Other Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={page.data.other_contribution_percentage} />
                        </div>
                        <span class="show-print">
                            {page.data.other_contribution_percentage ? `${page.data.other_contribution_percentage}%` : ''}
                        </span>
                    {/if}
                </td>
                <td>
                    {#if !page.duplicate}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Pre-Tax Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={$planGeneratorStore.plan.target_ira_contribution} />
                        </div>
                        <span class="show-print">
                            {$planGeneratorStore.plan.target_ira_contribution ? `${$planGeneratorStore.plan.target_ira_contribution}%` : ''}
                        </span>
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Pre-Tax Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={page.data.pre_tax_contribution_percentage} />
                        </div>
                        <span class="show-print">
                            {page.data.pre_tax_contribution_percentage ? `${page.data.pre_tax_contribution_percentage}%` : ''}
                        </span>
                    {/if}
                </td>
                <td>
                    {#if !page.duplicate}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Post-Tax Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={$planGeneratorStore.plan.target_roth_contribution} />
                        </div>
                        <span class="show-print">
                            {$planGeneratorStore.plan.target_roth_contribution ? `${$planGeneratorStore.plan.target_roth_contribution}%` : ''}
                        </span>
                    {:else}
                        <div class="input-group hide-print">
                            <NumberInput
                                placeholder="Post-Tax Contribution"
                                retirementSlide={true}
                                type="percent"
                                bind:value={page.data.post_tax_contribution_percentage} />
                        </div>
                        <span class="show-print">
                            {page.data.post_tax_contribution_percentage ? `${page.data.post_tax_contribution_percentage}%` : ''}
                        </span>
                    {/if}
                </td>
            {/if}
        </tr>
    </tbody>
</table>

<style lang="stylus" scoped>#retirement-previous-contribution {
  opacity: 50%;
  background-color: #f5f5f5;
}
@media screen and (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
  }
}
</style>
