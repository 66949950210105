<script>
    import Clients from '../components/Dashboard/Clients.svelte';
    import PlaidStatus from '../components/Dashboard/PlaidStatus.svelte';
</script>

<div class="flex-wrapper main-wrapper container w90">
    <a
        id="tutorial-link"
        href="https://docs.google.com/document/d/1nlaXk1_g4kLaROBg-AqOQVeGQ9fhhKDFn-jE4E2EdBU/edit?usp=sharing"
        target="_blank">
        Plan Generator Tutorials
    </a>
</div>

<div class="flex-wrapper main-wrapper container w90">
    <div class="column column-double">
        <Clients />
    </div>
    <div class="break"></div>
    <!-- split into rows for mobile -->
    <div class="column column-single">
        <PlaidStatus />
    </div>

</div>


<style lang="stylus" scoped>#tutorial-link {
  display: inline-block;
  margin-bottom: 10px;
  color: var(--color-green-light);
}
#tutorial-link:hover {
  color: var(--color-dark-gray);
}
.message {
  text-align: center;
}
.main-wrapper .column-single {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 340px;
  flex: 1 1 340px;
  margin-right: 0%;
}
.main-wrapper .column-double {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 calc(95% - 340px);
  flex: 1 1 calc(95% - 340px);
  margin-right: 3%;
}
@media screen and (max-width: 768px) {
  .main-wrapper {
    flex-flow: column wrap;
  }
  .main-wrapper .column-double {
    margin-right: 0%;
  }
}
</style>
