<script>
    import { planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';
    import NumberInput from '../UXElements/NumberInput.svelte';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';

    export let wizard;

    let monthsToReachGoal = null;

    $: {
        let currentEFBalance = 0;
        let targetMonthsSaved = 0;

        if ($planGeneratorStore.plan.current_emergency_fund_balance) {
            currentEFBalance = $planGeneratorStore.plan.current_emergency_fund_balance;
        }

        if ($planGeneratorStore.plan.target_months_of_expenses_saved) {
            targetMonthsSaved = $planGeneratorStore.plan.target_months_of_expenses_saved;
        }

        const totalNeeded = monthlyTotalExpenses * targetMonthsSaved - currentEFBalance;
        const months = Math.ceil(totalNeeded / $planGeneratorStore.plan.emergency_fund_savings_per_month);

        if (!isFinite(months) && totalNeeded > 0) {
            monthsToReachGoal = `This goal cannot be reached at this time`;
        } else if (months > 0) {
            const monthLabel = months === 1 ? 'month' : 'months';
            monthsToReachGoal = `${accounting.formatNumber(months)} ${monthLabel}`;
        } else {
            monthsToReachGoal = `You've already reached it!`;
        }
    }

    $: monthsOfExpensesSaved = wizard.getCurrentMonthsOfExpensesSaved() ? wizard.getCurrentMonthsOfExpensesSaved() : 0;
    $: monthlyTotalExpenses = wizard.getExpenses('all', 'monthly') ? wizard.getExpenses('all', 'monthly') : 0;

</script>

<h1 id="months-of-expenses-saved" class="page-title">Months of Expenses Saved</h1>

<h3>Emergency Fund Goal</h3>
<p>
    An Emergency Fund provides you with the power to deal with life's unexpected events
    without breaking a sweat or going into debt. It can also give you the freedom to make a
    bigger financial decision like pursuing a career change or moving somewhere new.
</p>

<table class="two-column-table">
    <thead>
        <tr>
            <th>What Do I Need to Know?</th>
            <th>What Are My Numbers?</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>How long could your savings currently sustain you?</td>
            <td>{Math.floor(monthsOfExpensesSaved)} {Math.floor(monthsOfExpensesSaved) === 1 ? `month` : `months`}</td>
        </tr>
        <tr>
            <td>Current Total Monthly Expenses</td>
            <td>{accounting.formatMoney(monthlyTotalExpenses)}</td>
        </tr>
        <tr>
            <td>How much should you <strong><em>ideally</em></strong> have in savings?</td>
            <td>
                {accounting.formatMoney(monthlyTotalExpenses)} X
                <span class="hide-print">
                    <span class="input-group">
                        <NumberInput additionalClass={true} placeholder="# of Months" type="target" bind:value={$planGeneratorStore.plan.target_months_of_expenses_saved} />
                    </span>
                </span>
                <span class="show-print">{accounting.formatNumber($planGeneratorStore.plan.target_months_of_expenses_saved)}</span>
                {Math.floor($planGeneratorStore.plan.target_months_of_expenses_saved) === 1 ? `month` : `months`} = {accounting.formatMoney((monthlyTotalExpenses * $planGeneratorStore.plan.target_months_of_expenses_saved))}
            </td>
        </tr>
        <tr>
            <td>
                Savings Balance
            </td>
            <td>
                <div class="hide-print">
                    <span class="input-group-lower">
                        <NumberInput placeholder="Current Savings Balance" type="dollar" bind:value={$planGeneratorStore.plan.current_emergency_fund_balance} />
                    </span>
                </div>
                <span class="show-print">
                    {accounting.formatMoney($planGeneratorStore.plan.current_emergency_fund_balance)}
                </span>
            </td>
        </tr>
        <tr>
            <td>How much should I start saving per month to this goal?</td>
            <td>
                <div class="hide-print">
                    <span class="input-group-lower">
                        <NumberInput placeholder="Savings Per Month" type="dollar" bind:value={$planGeneratorStore.plan.emergency_fund_savings_per_month} />
                    </span>
                </div>
                <span class="show-print">{accounting.formatMoney($planGeneratorStore.plan.emergency_fund_savings_per_month)}</span>
            </td>
        </tr>
        <tr>
            <td>When will I reach my goal?</td>
            <td>{ monthsToReachGoal }</td>
        </tr>
    </tbody>
</table>

<h3>Saving Tips</h3>
<ul>
    <li>
        <strong>Automate It:</strong>
        Automation takes the decision-making process out of developing good savings habits.
        Almost all banks will allow you to schedule automatic transfers from one account to
        another.
    </li>
    <li>
        <strong>Separate It:</strong>
        It's helpful to keep your Emergency Fund in a separate savings account so you know
        how much is truly set aside for this goal and increase the chances of your allowing
        it to grow out of sight, out of mind
    </li>
    <li>
        <strong>Grow It:</strong>
        Your savings should be in a high yield savings account that can offer you a higher
        interest rate than traditional banks.
        <a href="https://financialgym.com/bff-approved-hysas" target="_blank">
            Here's a link to some of our favorites.
        </a>
    </li>
</ul>

<TrainerCommentComponent type={'emergency_fund_trainer_comments'} />

<style lang="stylus" scoped>.input-group {
  display: inline-block;
  width: 40%;
}
.input-group-lower {
  width: 100%;
}
</style>
