<script>
    export let label;
    export let maxLength;
    export let placeholder;
    export let value;
    export let required = false;
</script>

<label for={label} class="label">
    {label.split('-')[1] ? label.split('-')[1] : label}
    {#if required}
        <span class="required">*</span>
    {/if}
    <small class="text-muted {maxLength - value.length === 0 ? 'alert-primary' : ''}">
        {#if maxLength - value.length}
            {maxLength - value.length} characters remaining
        {:else}
            Limit reached!
        {/if}
    </small>
</label>
<input
    id={label}
    type="text"
    maxlength={maxLength}
    placeholder={placeholder}
    bind:value="{value}" />

<style lang="stylus" scoped>.label {
  text-transform: capitalize;
}
.text-muted {
  color: var(--color-gray-medium);
}
</style>
