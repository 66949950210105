<script>
    import FeatherIcon from '../UXElements/FeatherIcon.svelte';

    export let type;
    export let results;
    export let currentPage;
    export let amtPerPage;
    export let lastPage;
    export let totalResults;

    export let update;

    // Range to use to decide if the current page is close enough
    // to the start/end, and how many away to render if somewhere in the middle
    let range;
    // The maximum number of pages to show in the pagination button line
    let maxPagesShown;

    let innerWidth;
    let attrs;

    $: if (innerWidth <= 520) {
        attrs = {
            height: '10px',
            width: '10px',
            viewBox: '0 0 24 18'
        };
        if (innerWidth <= 425) {
            range = 2;
            maxPagesShown = 3;
        } else {
            range = 3;
            maxPagesShown = 5;
        }
    } else {
        range = 3;
        maxPagesShown = 5;
        attrs = {
            height: '13px',
            width: '13px',
            viewBox: '0 0 24 18'
        };
    }

    // Handle moving to a page for pagination
    const moveToPage = (option) => {
        // Handle moving by an increment
        if (typeof(option) === 'string') {
            if (option === 'first') {
                currentPage = 1;
            } else if (option === 'prev') {
                currentPage = Math.max(1, currentPage-1);
            } else if (option === 'next') {
                currentPage = Math.min(lastPage, currentPage+1);
            } else if (option === 'last') {
                currentPage = lastPage;
            }

        // Handle moving to a specific page
        } else if (typeof(option) === 'number') {
            // Page must be within possible range
            if (option > 0 && option <= lastPage) {
                currentPage = option;
            }
        }

        // Update the results with the new input
        update();
    };
</script>

<svelte:window bind:innerWidth={innerWidth}/>

<!-- Pagination management -->
{#if lastPage > 1}
    <div
        class="pagination-container-{innerWidth <= 520 ? 'small' : 'large'}">
        <!-- Show the starting point, a min of 1 and the end point, min of the end of the list -->
        {((currentPage-1) * amtPerPage) + 1}-{((currentPage-1) * amtPerPage) + results.length} of {totalResults} {type}
    </div>

    <div
        class="pagination-container-{innerWidth <= 520 ? 'small' : 'large'}"
        id="pagination-buttons">

        <!-- Decrease page buttons -->
        {#if currentPage > 1}
            <div class="button-group button-group-2">
                <button
                    class="button small secondary"
                    on:click="{() => moveToPage('first')}">
                    <FeatherIcon
                        icon="chevrons-left"
                        {attrs} />
                </button>
                <button
                    class="button small secondary"
                    on:click="{() => moveToPage('prev')}">
                    <FeatherIcon
                        icon="chevron-left"
                        {attrs} />
                </button>
            </div>
        {/if}

        <!-- Select page buttons -->
        <div class="button-group button-group-{lastPage > 1 && lastPage <= maxPagesShown ? lastPage : maxPagesShown}">
            {#each Array(lastPage) as _, pageIndex}
                <!--
                    If the current page is near the start, render the
                    buttons within a range of the start of the button list
                -->
                {#if currentPage <= range}
                    {#if pageIndex+1 <= maxPagesShown}
                        <button
                            class="button small {currentPage === pageIndex+1 ? "primary" : "secondary"}"
                            on:click="{() => moveToPage(pageIndex+1)}">
                            <!--
                                Add a ... to the end of the last page
                                number if it is not the last page
                            -->
                            {#if
                                pageIndex+1 === maxPagesShown &&
                                pageIndex+1 !== lastPage
                            }
                                {pageIndex+1}...
                            {:else}
                                {pageIndex+1}
                            {/if}
                        </button>
                    {/if}

                <!--
                    If the current page is near the end, render the
                    buttons within a range of the end of the button list
                -->
                {:else if currentPage > lastPage-range}
                    {#if pageIndex+1 > lastPage-maxPagesShown}
                        <button
                            class="button small {currentPage === pageIndex+1 ? "primary" : "secondary"}"
                            on:click="{() => moveToPage(pageIndex+1)}">
                            <!--
                                Add a ... to the start of the first
                                page number if it is not page 1
                            -->
                            {#if
                                pageIndex+1 === lastPage-maxPagesShown+1 &&
                                pageIndex+1 !== 1
                            }
                                ...{pageIndex+1}
                            {:else}
                                {pageIndex+1}
                            {/if}
                        </button>
                    {/if}

                <!--
                    If neither of the previous conditions are true,
                    the current page is not close enough to either end,
                    render the buttons if it is within a range of the current page
                -->
                {:else}
                    {#if range > Math.abs(currentPage - (pageIndex+1))}
                        <button
                            class="button small {currentPage === pageIndex+1 ? "primary" : "secondary"}"
                            on:click="{() => moveToPage(pageIndex+1)}">
                                <!--
                                    When rendering pages in the middle,
                                    there will always the ... on the sides
                                -->
                                <!--
                                    Add a ... before the first visable page number
                                -->
                                {#if currentPage - (pageIndex+1) === range-1}
                                    ...{pageIndex+1}

                                <!--
                                    Add a ... after the last visable page number
                                -->
                                {:else if currentPage - (pageIndex+1) === -(range-1)}
                                    {pageIndex+1}...

                                {:else}
                                    {pageIndex+1}
                                {/if}
                        </button>
                    {/if}
                {/if}
            {/each}
        </div>

        <!-- Increase buttons -->
        {#if currentPage < lastPage}
            <div class="button-group button-group-2">
                <button
                    class="button small secondary"
                    on:click="{() => moveToPage('next')}">
                    <FeatherIcon
                        icon="chevron-right"
                        {attrs} />
                </button>
                <button
                    class="button small secondary"
                    on:click="{() => moveToPage('last')}">
                    <FeatherIcon
                        icon="chevrons-right"
                        {attrs} />
                </button>
            </div>
        {/if}
    </div>
{/if}

<style lang="stylus" scoped>.pagination-container-large {
  padding: 0px 10px 10px 10px;
}
.pagination-container-small {
  padding: 0px 0px 10px 0px;
}
#pagination-buttons {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 5px;
}
#pagination-buttons .button-group {
  display: inline-grid;
  grid-gap: 2px;
}
#pagination-buttons .button-group-2 {
  grid-template-columns: repeat(2, auto);
}
#pagination-buttons .button-group-3 {
  grid-template-columns: repeat(3, auto);
}
#pagination-buttons .button-group-4 {
  grid-template-columns: repeat(4, auto);
}
#pagination-buttons .button-group-5 {
  grid-template-columns: repeat(5, auto);
}
#pagination-buttons button {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
}
@media screen and (max-width: 520px) {
  #pagination-buttons {
    grid-gap: 2px;
  }
}
@media screen and (max-width: 375px) {
  #pagination-buttons button {
    padding: 0.25rem 0.5rem;
  }
}
</style>
