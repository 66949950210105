<script>
    import { onMount, onDestroy, afterUpdate } from 'svelte';
    import { fade, fly } from 'svelte/transition';

    import { statusHandler, intakeDataStore, showNotes } from '../shared/store';
    import Request from '../classes/Request/Request';

    $: clientId = $intakeDataStore.client_info.id;
    let noteBox;
    let timer = 0;
    let toggleText = '&raquo;';
    let updated = false;
    let xVal = -1000;
    let yVal = 0;
    const interval = setInterval(() => { timer += 1 }, 1000);

    onMount(() => {
        init();
        window.addEventListener('resize', init);
     });

    const init = () => {
        if (window.innerWidth < 1025) {
            xVal = 0;
            yVal = 1000;
        } else {
            xVal = -1000;
            yVal = 0;
        }
    };

    onDestroy(() => { clearInterval(interval); });

    const resetTimer = () => { timer = 0; updated = false; };

    const getNotes = async () => {
        const requestClassProps = {
            url: `clients/${clientId}/notes/`
        };

        requestClassProps.errorHandler = (errorResponse = undefined) => {
            if (errorResponse === undefined) {
                $statusHandler = {
                    type: 'error',
                    message: 'An unexpected error occurred. Please try again.'
                }
                return false;
            }

            $statusHandler = {
                type: 'error',
                message: errorResponse.data
            }
            return false;
        }

        requestClassProps.successHandler = (successResponse) => {
            $intakeDataStore.notes[0].id = successResponse.data[0].id
            return true;
        }

        const getNotesRequest = new Request(requestClassProps);

        await getNotesRequest.requestData();
    };

    const saveNotes = async () => {
        const requestClassProps = {
            params: {'notes': $intakeDataStore.notes },
            type: 'post',
            url: `clients/${clientId}/intake/`
        };

        requestClassProps.errorHandler = (errorResponse = undefined) => {
            if (errorResponse === undefined) {
                $statusHandler = {
                    type: 'error',
                    message: 'An unexpected error occurred. Please try again.'
                }
                return false;
            }

            $statusHandler = {
                type: 'error',
                message: errorResponse.data
            }
            return false;
        }

        requestClassProps.successHandler = async (successResponse) => {
            if (!$intakeDataStore.notes[0].id) {
                await getNotes();
            }
            return true;
        }

        const saveNoteRequest = new Request(requestClassProps);

        updated  = await saveNoteRequest.requestData();
    };

    $: {
        if ($showNotes) {
            toggleText = '&laquo;';
            if (noteBox !== undefined && noteBox !== null) {
                noteBox.focus();
            }
        } else {
            toggleText = '&raquo;';
        }

        if (timer > 2 && !updated) {
            saveNotes();
        }
    }
</script>

{#if $intakeDataStore.notes[0]}
    {#if $showNotes}
        <div aria-labelledby="Notes" class="fly-out" transition:fly="{{ x: xVal, y: yVal, duration: 500 }}">
            <textarea
                rows="8"
                placeholder="Notes"
                on:keyup="{resetTimer}"
                bind:this={noteBox}
                bind:value="{$intakeDataStore.notes[0].description}"></textarea>
            <button class="button primary" on:click="{showNotes.set(!$showNotes)}"><span class="close-toggle">{ @html toggleText }</span></button>
        </div>
    {/if}
{/if}

<style lang="stylus" scoped>.fly-out {
  background-color: #fff;
  z-index: 500;
}
@media screen and (min-width: 1025px) {
  .fly-out {
    box-shadow: 0 15px 10px #777;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50vw;
  }
  .fly-out button {
    border-radius: 0;
    position: absolute;
    width: 5%;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .fly-out textarea {
    background-color: #f7f7f7;
    display: block;
    height: 100vh;
    padding: 1em;
    width: 95%;
  }
}
@media screen and (max-width: 1024px) {
  .fly-out {
    box-shadow: 0 -2px 10px #777;
    height: 49vh;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fly-out button {
    border-radius: 0;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
  }
  .fly-out button .close-toggle {
    display: block;
    transform: rotate(270deg);
  }
  .fly-out textarea {
    height: 48vh;
    margin-top: 1%;
    padding: 2em 1em 1em 1em;
    width: 100%;
  }
}
</style>
