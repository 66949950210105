<script>
    import { onMount } from 'svelte';
    import { trainerDataStore, planGeneratorStore } from '../../shared/store';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import QuillComponent from '../UXElements/QuillComponent.svelte';

    let avatarErrorMessage = '';
    let avatar = null;

    // Get the url to find the server at
    // const url = getApiRoot();

    onMount(async () => {
        // Used to be
        // avatar = $trainerDataStore.image;
        // Is now
        if (window.localStorage.getItem('trainer-avatar') !== null) {
            avatar = window.localStorage.getItem('trainer-avatar');
        } else if ($trainerDataStore.hasAvatar === true) {
            avatarErrorMessage = 'Please re-upload your avatar image';
        }

        // Handle the avatar
        const inputElement = document.getElementById('imgupload');
        inputElement.addEventListener('change', handleFiles, false);
    });

    function handleFiles() {
        const file = this.files[0];
        updateTrainerAvatar(file);
    }

    const updateTrainerAvatar = async file => {
        // New temp way of local saving
        // Make changes depending on the file passed in
        if (file === null) {
            window.localStorage.removeItem('trainer-avatar', null);
            avatar = null;
            $trainerDataStore.hasAvatar = false;
            updateTrainer();
        } else {
            // Check if less then 500kb
            const isProperFileSize = file.size / 1000 < 500;
            if (!isProperFileSize) {
                avatarErrorMessage = 'Max file size is 500 kb';
            }
            // Check if file is an allowed file extention
            const allowedFileTypes = ['jpeg', 'png', 'jpg', 'gif'];
            const isAllowedFileType = allowedFileTypes.includes(
                file.name
                    .split('.')
                    .pop()
                    .toLowerCase()
            );
            if (!isAllowedFileType) {
                avatarErrorMessage = 'Unsupported file extension';
            }

            // Save file to local storage if cases pass
            if (isProperFileSize && isAllowedFileType) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    avatarErrorMessage = '';
                    window.localStorage.setItem('trainer-avatar', reader.result);
                    avatar = window.localStorage.getItem('trainer-avatar');
                    $trainerDataStore.hasAvatar = true;
                    updateTrainer();
                };
                reader.onerror = error => {
                    avatarErrorMessage = error;
                };
            }
        }
    };

    const updateTrainer = async () => {
        // Update server side trainer info for if an avatar is expected
        const { data, status } = await api.updateTrainerInfo(
            $trainerDataStore,
            $trainerDataStore.id
        );
        if (status === 200) {
            avatarErrorMessage = '';
        } else {
            avatarErrorMessage = data;
        }

        // Get updated server side info to ensure expected changes
        const response = await api.getUserData('trainers');
        if (response.status === 200) {
            $trainerDataStore = { ...response.data, $trainerDataStore };
        } else {
            avatarErrorMessage = 'Upload Failed, Try Again!';
        }
    };
</script>

<h1 class="page-title">An Encouraging Note from your B.F.F.</h1>
<QuillComponent type="{'encouraging_note'}" />

<!-- Container for avatar and related parts -->
<div class="trainer-avatar-container">
    <!-- Error message -->
    {#if avatarErrorMessage !== ''}
        <div
            class="error alert alert-danger hide-print"
            on:click="{() => (avatarErrorMessage = '')}">
            {avatarErrorMessage}
        </div>
    {/if}
    <!-- Container for image and relitive positioned button -->
    <div class="trainer-avatar-image-container {avatar ? 'display-block' : 'display-none'}">
        <!-- Image -->
        <!-- src was - src="{avatar ? url + avatar : ''}" -->
        <img
            class="trainer-avatar-image"
            src="{avatar ? avatar : ''}"
            alt="Trainer Avatar"
            on:click="{() => document.getElementById('imgupload').click()}"
            data-cy="avatar" />
        <!-- Remove avatar button -->
        <button
            class="button small btn-danger remove-avatar-button"
            on:click="{() => updateTrainerAvatar(null)}">
            <FeatherIcon icon="x" />
        </button>
    </div>
</div>
<!-- Button -->

<button
    class="button small primary {avatar ? 'display-none' : 'display-block'}"
    on:click="{() => document.getElementById('imgupload').click()}">
    <label for="file-1" class="file-label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg> <span>Add a profile image</span></label>
</button>
<!-- Hidden file input -->
<input type="file" id="imgupload" class="display-none" />

<br />
<p>- {$trainerDataStore.first_name} {$trainerDataStore.last_name}</p>

<style lang="stylus" scoped>.display-block {
  display: block;
}
.display-none {
  display: none;
}
.error {
  cursor: pointer;
  margin-bottom: 5px;
}
.file-label svg {
  fill: var(--color-white);
}
.trainer-avatar-container {
  margin-top: 20px;
  width: 25%;
}
.trainer-avatar-container .trainer-avatar-image-container {
  position: relative;
  cursor: pointer;
}
.trainer-avatar-container .trainer-avatar-image-container .trainer-avatar-image {
  width: 100%;
}
.trainer-avatar-container .trainer-avatar-image-container .remove-avatar-button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.trainer-avatar-image-container:hover .remove-avatar-button {
  display: block;
}
</style>
