<script>
    import { link, location } from 'svelte-spa-router';
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import FeatherIcon from './FeatherIcon.svelte';

    import { showNotes } from '../../shared/store';

    const dispatch = createEventDispatcher();

    export let saveFn;
    export let id;
    export let saving;
    export let errorMessage;
    export let error;
    export let message = 'Save';
    export let page = null;

    // Client Info Only
    export let hasPlan = null;

    // Plan Only
    export let regenerating = null;
    export let regenerateMessage = null;

    export let expanded = false;

    const showModal = () => {
        dispatch('showModal', true);
    }

    const save = async () => {
        message = 'Saving'
        const status = await saveFn();

        switch (status) {
            case 'error':
                message = 'Error';
                document.getElementById('save-button').classList.add('btn-danger');
                document.getElementById('save-button').classList.remove('btn-primary');
                document.getElementById('save-button').classList.remove('primary');
                resetSaveButtonTimeout(2000);
                break;
            case 'success':
                message = 'Success!';
                document.getElementById('save-button').classList.add('btn-success');
                document.getElementById('save-button').classList.add('primary');
                document.getElementById('save-button').classList.remove('btn-danger');
                resetSaveButtonTimeout(2000);
                break;
            case 'synced':
                saving = false;
                message = 'Data Synced';
                document.getElementById('save-button').classList.add('btn-success');
                document.getElementById('save-button').classList.add('primary');
                document.getElementById('save-button').classList.remove('btn-danger');
                resetSaveButtonTimeout(2000);
                break;
            default:
                resetSaveButtonTimeout(0);
                break;
        }
    };

    const resetSaveButtonTimeout = (time) => {
        setTimeout(() => {
            if ($location !== '/') {
                saving = false;
                message = 'Save';
                document.getElementById('save-button').classList.add('btn-primary');
                document.getElementById('save-button').classList.add('primary');
                document.getElementById('save-button').classList.remove('btn-danger');
                document.getElementById('save-button').classList.remove('btn-success');
            }
        }, time);
    };

    onMount(() => {
        window.addEventListener("click", outsideExpand);
        window.addEventListener("touchstart", outsideExpand);
    });
    onDestroy(() => {
        window.removeEventListener("click", outsideExpand);
        window.removeEventListener("touchstart", outsideExpand);
    });

    const outsideExpand = (e) => {
        const stickySave = document.getElementById('sticky-save');
        if (!stickySave.contains(e.target)) {
            expanded = false;
        }
    }

    const expand = () => {
        expanded = !expanded;
    }
</script>


<div
    class="panel {error ? 'error' : ''} {expanded ? 'expanded' : 'collapsed'} sticky-save {page === 'plan' ? 'plan' : ''} hide-print"
    id="sticky-save"
    >
    {#if error}
        <p class="alert alert-danger hide-print error-message">{@html errorMessage}</p>
    {/if}
    {#if page}
        <button
            class="toggle {expanded ? 'close' : ''} button icon-button"
            title="{expanded ? 'Close' : 'Open'} Menu"
            aria-label="{expanded ? 'Close' : 'Open'} Menu"
            on:click={e => expand()}
            on:touchstart={e => expand()}>
            &laquo;
        </button>
    {/if}
    <div class="container">
        <div class="button-container">
            <button
                id="save-button"
                class="button small primary"
                disabled="{saving}"
                on:click="{() => save()}"
                data-cy="form-save-btn">
                {#if saving}
                    {#if !message.toLowerCase().includes('success')}
                        <span class="spin"><FeatherIcon icon="aperture" /></span>
                    {/if}
                {/if}
                {message}
            </button>
            {#if page === 'plan'}
                <button
                    id="regenerate-button"
                    class="button small secondary btn-{regenerateMessage.toLowerCase().includes('successfully') ? 'success' : 'warning'}"
                    disabled={regenerating}
                    on:click|preventDefault="{ showModal }"
                    aria-label="Regenerate plan"
                    data-target="#modal"
                    data-toggle="modal"
                    data-cy="form-regenerate-btn">
                    {#if regenerating}
                        {#if !regenerateMessage.toLowerCase().includes('successfully')}
                            <span class="spin"><FeatherIcon icon="aperture" /></span>
                        {/if}
                        {regenerateMessage}
                    {:else}
                        {regenerateMessage}
                    {/if}
                </button>
            {/if}
        </div>
        {#if page}
            <div class="link-container">
                <a 
                    class="{page === 'clients' ? 'disable' : ''}"
                    href="/clients/{id}"
                    on:click="{() => showNotes.set(false)}"
                    use:link >
                    Client Info
                </a>
                <a 
                    class="{page === 'intake' ? 'disable' : ''}"
                    href="/intake/{id}"
                    on:click="{() => showNotes.set(false)}"
                    use:link >
                    Intake
                </a>
                {#if (page === 'clients' && hasPlan) || page === 'intake' || page === 'plan'}
                    <a 
                        class="{page === 'plan' ? 'disable' : ''}"
                        href="/plan/{id}"
                        on:click="{() => showNotes.set(false)}"
                        use:link >
                        Plan
                    </a>
                {/if}
                {#if (page === 'plan' || page === 'intake')}
                    <button
                        class="button note-button icon-button"
                        on:click="{() => showNotes.set(!$showNotes)}" >
                        Notes
                    </button>
                {/if}
            </div>
        {/if}
    </div>
</div>

<style lang="stylus" scoped>.container {
  display: grid;
  grid-template-columns: 2fr 29fr 29fr 2fr;
  grid-template-rows: unset;
}
.button-container {
  grid-column-start: 2;
  justify-self: start;
}
.error-message {
  margin-top: unset;
  margin-bottom: 0.5em;
}
.link-container {
  align-self: center;
  justify-self: end;
}
.note-button {
  margin: 0 1em;
  color: var(--color-green-dark);
}
.note-button:hover {
  color: var(--color-gray-text);
}
.toggle {
  display: none;
  width: 20px;
  height: 20px;
  margin: -10px auto 5px auto;
  font-size: 1em;
  transform: rotate(90deg);
}
.toggle.close {
  transform: rotate(270deg);
}
.icon-button {
  outline: none;
}
a,
.button {
  text-transform: capitalize;
}
a {
  margin: 0 1em;
}
.disable {
  color: var(--color-gray-text) !important;
  pointer-events: none;
  cursor: default;
}
.sticky-save {
  border-radius: 0;
  border-top: 1px solid var(--color-green-dark);
  bottom: 0;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 500;
}
@media screen and (min-width: 1025px) {
  .sticky-save {
    width: calc(100% - 280px);
  }
}
@media screen and (max-width: 1024px) {
  .toggle {
    display: block;
  }
  .button {
    width: 25%;
  }
  .container {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
  }
  .button-container {
    grid-column-start: unset;
    justify-self: unset;
  }
  .link-container {
    justify-self: unset;
  }
  .sticky-save {
    height: 115px;
    transition: height 0.25s ease;
  }
  .error.collapsed.sticky-save {
    height: 110px;
  }
  .error.expanded.sticky-save {
    height: 140px;
  }
  .collapsed {
    height: 85px;
  }
}
@media screen and (max-width: 480px) {
  a {
    display: block;
    padding: 10px 0;
    margin: unset;
  }
  a::first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .container {
    grid-template-rows: 0fr 1fr;
  }
  .button {
    width: 100%;
  }
  .button:nth-child(2) {
    margin-top: 5px;
  }
  .collapsed .button:nth-child(2) {
    margin-top: 10px;
  }
  .sticky-save {
    height: 190px;
  }
  .sticky-save.plan {
    height: 280px;
  }
  .error.collapsed.sticky-save.plan {
    height: 110px;
  }
  .error.expanded.sticky-save {
    height: 280px;
  }
  .error.expanded.sticky-save.plan {
    height: 265px;
  }
  .collapsed {
    height: 80px;
  }
  .collapsed.sticky-save.plan {
    height: 80px;
  }
}
</style>
