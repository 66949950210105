<script>
    import { onDestroy } from 'svelte';
    import { link } from 'svelte-spa-router';
    import FileDownload from 'js-file-download';

    import {
        trainerDataStore,
        clientAfterUpdate,
        statusHandler
    } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import Request from '../../classes/Request/Request';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import Pagination from '../UXElements/Pagination.svelte';
    import Search from '../UXElements/Search.svelte';


    // Loading clients
    let loadedClients = false;
    let loadFail = false;

    // Pagination
    let currentPage = 1;
    let amtPerPage = 15;
    let lastPage = 1;
    let totalResults = 0;
    let search = '';

    // CSV downloading
    let hasRun = false;
    let csvActions = {};

    $: if ($trainerDataStore.id !== null && !hasRun) {
        getTrainerClients();
        hasRun = true;
    }

    // A client has been updated after clients have been gathered, update the client
    $: if ($trainerDataStore.clients.length > 0 && $clientAfterUpdate !== null) {
        $trainerDataStore.clients = $trainerDataStore.clients.filter(
            clientEntry => $clientAfterUpdate.id !== clientEntry.id
        );
        $trainerDataStore.clients.push($clientAfterUpdate);
        $clientAfterUpdate = null;

        const sortedData = $trainerDataStore.clients.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        $trainerDataStore.clients = sortedData;
    }

    const getTrainerClients = async (newInput = false) => {
        // Start loading animation
        loadedClients = false;

        // Track old values for changes
        const previousSearch = search;
        const previousPage = currentPage;

        // If this call is made due to a new input,
        // wait 500ms before making a request to ensure the user isn't still typing
        setTimeout(async () => {
            // Check if the user has made more changes to the search,
            // don't continue with the request if they have
            if (search !== previousSearch) {
                return;
            }

            if (newInput) {
                currentPage = 1;
            }
            
            const requestClassProps = {
                url: `trainers/${$trainerDataStore.id}/clients/${currentPage}/${amtPerPage}/`
            };

            if (search !== '') {
                requestClassProps.url = `trainers/${$trainerDataStore.id}/clients/${search}/${currentPage}/${amtPerPage}/`;
            }

            requestClassProps.errorHandler = (errorResponse = undefined) => {
                $statusHandler.type = 'error';

                if (errorResponse === undefined) {
                    $statusHandler.message = 'An unexpected error occurred. Please try again.';
                    return false;
                }

                $statusHandler.message = errorResponse.data;
                window.scrollTo(0, 0);
                return false;
            }

            requestClassProps.successHandler = (successResponse) => {
                if (search === previousSearch) {
                    const sortedData = successResponse.data.paginated_clients.sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });

                    $trainerDataStore.clients = sortedData;

                    currentPage = successResponse.data.current_page;
                    lastPage = successResponse.data.last_page;
                    totalResults = successResponse.data.total_num_of_clients;
                }

                return true;
            }
            
            const getTrainerClientsRequest = new Request(requestClassProps);

            loadedClients = await getTrainerClientsRequest.requestData();
        },
        newInput ? 400 : 0);
    };

    const downloadCSV = async clientId => {
        const timeBetweenRequests = 2000;

        if (
            csvActions.hasOwnProperty(clientId) &&
            new Date().getTime() - csvActions[clientId] < timeBetweenRequests
        ) {
            return;
        }

        csvActions[clientId] = new Date().getTime();
        const getCSVResponse = await api.request('get', `clients/${clientId}/csv/`);
        const fileName = getCSVResponse.headers['content-disposition'].replace(
            'attachment; filename=',
            ''
        );

        FileDownload(getCSVResponse.data, fileName);

        if (getCSVResponse.status >= 400) {
            $statusHandler = {
                type: 'error',
                message: `CSV Error: ${getCSVResponse.statusText}`
            }
            window.scrollTo(0, 0);
        } else {
            if (getCSVResponse.statusText === '') {
                $statusHandler = {
                    type: null,
                    message: ''
                }
            } else {
                $statusHandler = {
                    type: 'error',
                    message: `CSV Error: ${getCSVResponse.statusText}`
                }
            }
        }
    };

    const clearSearch = async () => {
        if (search !== '') {
            search = '';
            getTrainerClients(true);
        }
    };

    onDestroy(() => {
        $statusHandler = {
            type: null,
            message: ''
        }
    });
</script>

<div class="milestone-goal container">
    <h3 class="panel-title">Clients</h3>
    
    <Search
        placeholder="Search for client by name..."
        bind:input="{search}"
        searchFn="{() => getTrainerClients(true)}"
        clearSearchFn="{async () => clearSearch()}"
        />

    <div class="panel">
        {#if $statusHandler.type !== null}
            <div class="{$statusHandler.type === 'error' ? 'alert' : 'success'} message">
                {@html $statusHandler.message}
            </div>
        {/if}

        {#if loadedClients}
            {#if $trainerDataStore.clients.length > 0}
                <table class="milestone-goal-table select-multi hide-headings">
                    <thead>
                        <tr class="table-headings">
                            <th class="check check-all"></th>
                            <th>
                                <h5>Member Name</h5>
                            </th>
                            <th>
                                <h5>Intake</h5>
                            </th>
                            <th>
                                <h5>Plan</h5>
                            </th>
                            <th>
                                <h5>Plan CSV</h5>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {#each $trainerDataStore.clients as client}
                            <tr>
                                <td class="check"></td>
                                <td class="client-info">
                                    <img
                                        class="image-round image-round-small"
                                        src="{client.users.length > 0 ? client.users[0].gravatar_url : `${S3BUCKET_URL}/logo-mark.jpg`}"
                                        alt="User's Name" />
                                    <h4>
                                        <a
                                            href="/clients/{client.id}"
                                            title="{client.name}'s Client Info"
                                            aria-label="{client.name}'s Client Info"
                                            use:link >
                                            {client.name.length > 0 ? client.name : client.email}
                                        </a>
                                    </h4>
                                    <a
                                        class="open-link open-mobile-actions"
                                        href="/clients/{client.id}"
                                        title="{client.name}'s Client Info"
                                        aria-label="{client.name}'s Client Info"
                                        use:link 
                                        rel="mobActions">
                                        &hellip;
                                    </a>
                                </td>
                                <td>
                                    <a
                                        class="cell medium-1"
                                        href="/intake/{client.id}"
                                        data-cy="intake-button-{client.id}"
                                        title="{client.name}'s Intake"
                                        aria-label="{client.name}'s Intake"
                                        use:link >
                                        <FeatherIcon
                                            icon="layers"
                                            attrs="{{ height: '16px', width: '16px' }}" />
                                        Intake
                                    </a>
                                </td>
                                <td>
                                    {#if client.has_plan}
                                        <a
                                            class="cell medium-3"
                                            title="{client.name}'s Plan"
                                            aria-label="{client.name}'s Plan"
                                            href="/plan/{client.id}"
                                            use:link >
                                            <FeatherIcon
                                                icon="file-text"
                                                attrs="{{ height: '16px', width: '16px' }}" />
                                            Plan
                                        </a>
                                    {/if}
                                </td>
                                <td>
                                    {#if client.has_plan}
                                        <button
                                            title="Download {client.name}'s CSV"
                                            aria-label="Download {client.name}'s CSV"
                                            class="cell medium-3 button icon-button"
                                            on:click|preventDefault="{() => downloadCSV(client.id)}" >
                                            <FeatherIcon
                                                icon="download"
                                                attrs="{{ height: '16px', width: '16px' }}" />
                                            CSV
                                        </button>
                                    {/if}
                                </td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            {:else if $trainerDataStore.clients.length === 0 && search === ''}
                <div
                    class="no-clients alert alert-info {$statusHandler.type === 'success' ? 'message' : ''}">
                    You currently have no clients.
                    <a
                        class="button small primary"
                        href="/new-client"
                        use:link >
                        Click here to create a new client!
                    </a>
                </div>
            {:else if $trainerDataStore.clients.length === 0 && search !== ''}
                <div class="alert alert-info">You currently have no clients by that name.</div>
            {/if}
        {:else}
            <div class="spin">
                <FeatherIcon icon="aperture" />
            </div>
        {/if}
    </div>

    <div>
        <Pagination
            type="{'Clients'}"
            results="{$trainerDataStore.clients}"
            bind:currentPage
            {amtPerPage}
            {lastPage}
            {totalResults}
            update="{getTrainerClients}" />
    </div>
</div>

<style lang="stylus" scoped>.icon-button {
  color: var(--color-gray-text);
}
#tutorial-link {
  display: inline-block;
  margin-bottom: 10px;
  color: var(--color-green-light);
}
#tutorial-link:hover {
  color: var(--color-dark-gray);
}
.message {
  text-align: center;
}
.no-clients {
  text-align: center;
}
.no-clients.message {
  padding-top: 1em;
}
.spin {
  text-align: center;
  display: block;
}
.success {
  color: var(--color-green-light);
}
@media screen and (max-width: 768px) {
  th {
    padding: 5px 20px;
  }
  th:first-child,
  th:last-child {
    display: none;
  }
}
</style>
