<script>
    import { intakeDataStore, planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';
    import DebtMinimumsTable from './DebtMinimumsTable.svelte';

    export let wizard;

    let allGoals = [];
    let monthlyTotalContribution = 0;
    let annualSalaryGoal = 0;
    let totalMonthlySavings = 0;
    let emergencyFund = 0;
    let retirementSavings = 0;
    let debtCrushingExtra = 0;
    let federalLoan = 0;
    let privateLoan = 0;
    let minDebtPayments;

    $: currentFixedExpenses = wizard.getExpenses('fixed', 'monthly');
    $: creditScore = wizard.getCreditScore();
    $: totalCreditCardDebt = wizard.getLiabilities(['charge_card', 'credit_card'], 'all');

    $: if ($intakeDataStore.goals) {
        allGoals = wizard.goalManager.allGoals;
        monthlyTotalContribution = 0;
        allGoals.forEach(goal => {
            goal.newContribution = Math.round(parseFloat(isNaN(goal.newContribution) ? 0 : goal.newContribution), 2);

            monthlyTotalContribution += goal.newContribution;

            if (!goal.showInPlanIncomeGoals) {
                monthlyTotalContribution -= goal.newContribution;
            }

            $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
        })

    }

    $: {
        emergencyFund = ($planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month ? ($planGeneratorStore.plan.income_goal_emergency_fund_savings_per_month ? parseFloat($planGeneratorStore.plan.income_goal_emergency_fund_savings_per_month) : 0) : 0);

        retirementSavings = ($planGeneratorStore.plan.income_goal_show_retirement_savings ? ($planGeneratorStore.plan.income_goal_retirement_savings ? parseFloat($planGeneratorStore.plan.income_goal_retirement_savings) : 0) : 0);

        debtCrushingExtra = ($planGeneratorStore.plan.income_goal_show_debt_crushing_extra ? ($planGeneratorStore.plan.income_goal_debt_crushing_extra ? parseFloat($planGeneratorStore.plan.income_goal_debt_crushing_extra) : 0) : 0);

        federalLoan = ($planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount ? ($planGeneratorStore.plan.income_goal_student_loan_management_federal_amount ? parseFloat($planGeneratorStore.plan.income_goal_student_loan_management_federal_amount) : 0) : 0);

        privateLoan = ($planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount ? ($planGeneratorStore.plan.income_goal_student_loan_management_private_amount ? parseFloat($planGeneratorStore.plan.income_goal_student_loan_management_private_amount) : 0) : 0);

        totalMonthlySavings = monthlyTotalContribution + emergencyFund + retirementSavings + debtCrushingExtra + federalLoan + privateLoan;

        annualSalaryGoal =
        (parseFloat(currentFixedExpenses) +
            parseFloat(minDebtPayments) +
            parseFloat($planGeneratorStore.plan.monthly_ideal_variable_expenses) +
            parseFloat(totalMonthlySavings)) /
            0.65 * 12;
    }

    // Init if the plan does not already have a value
    $: $planGeneratorStore.plan.monthly_ideal_variable_expenses =
        $planGeneratorStore.plan.monthly_ideal_variable_expenses === null ||
        $planGeneratorStore.plan.monthly_ideal_variable_expenses === undefined
            ? Math.round(wizard.getExpenses('variable', 'monthly'), 2)
            : $planGeneratorStore.plan.monthly_ideal_variable_expenses;

    if (!$planGeneratorStore.plan.id) {
        $planGeneratorStore.plan.monthly_ideal_variable_expenses = Math.round(wizard.getExpenses('variable', 'monthly'), 2);
        $planGeneratorStore.plan.income_goal_emergency_fund_savings_per_month = $planGeneratorStore.plan.emergency_fund_savings_per_month;
        $planGeneratorStore.plan.income_goal_debt_crushing_extra = $planGeneratorStore.plan.debt_crushing_extra;
        $planGeneratorStore.plan.income_goal_student_loan_management_federal_amount = $planGeneratorStore.plan.student_loan_management_federal_amount;
        $planGeneratorStore.plan.income_goal_student_loan_management_private_amount = $planGeneratorStore.plan.student_loan_management_private_amount;
    }

    const toggleGoalInIncomeGoals = (index) => {
        allGoals[index].showInPlanIncomeGoals = !allGoals[index].showInPlanIncomeGoals;
        $intakeDataStore.goals[index].show_in_plan_income_goals = !$intakeDataStore.goals[index].show_in_plan_income_goals;
    }

    const togglePaymentGoalInIncomeGoals = (type) => {
        const showPayment = 'income_goal_show_' + type;
        $planGeneratorStore.plan[showPayment] = !$planGeneratorStore.plan[showPayment];
    }
</script>

<h1 class="page-title">Income Goal</h1>

<p>
    Income is an important tool in helping you accomplish all of your goals. Here at the Financial Gym we want you to live your best life, and one way to do that is optimizing your earning potential.
</p>

<table>
    <thead>
        <tr>
            <th colspan="2">Ideal Budget</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>CURRENT FIXED EXPENSES</th>
            <td>{accounting.formatMoney(currentFixedExpenses)}</td>
        </tr>
        <DebtMinimumsTable {wizard} bind:minDebtPayments={minDebtPayments}/>
        <tr>
            <th>IDEAL VARIABLE SPEND</th>
            <td class="ideal-variable-spend">
                <div class="input-group hide-print">
                    <NumberInput type="dollar" bind:value={$planGeneratorStore.plan.monthly_ideal_variable_expenses}/>
                </div>
                <span class="show-print">
                    {accounting.formatMoney($planGeneratorStore.plan.monthly_ideal_variable_expenses)}
                </span>
            </td>
        </tr>
        <tr>
            <th>IDEAL SAVINGS GOALS/DEBT REPAYMENT</th>
            <td>{accounting.formatMoney(totalMonthlySavings)}</td>
        </tr>
        <tr class="{!$planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month ? 'hide-goal hide-print' : ''}">
            <td class="sub-goal">Emergency Savings Goal</td>
            <td>
                <div class="hide-print grid-row grid-2 sub-goal-amount">
                    <NumberInput
                        placeholder="Emergency Savings Goal"
                        type="dollar"
                        disabled={!$planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month}
                        bind:value={$planGeneratorStore.plan.income_goal_emergency_fund_savings_per_month}/>
                    <div class="income-goal-button-container">
                        <button
                            class="toggle-{$planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month ? 'off' : 'on'} income-goal-button button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInIncomeGoals('emergency_fund_savings_per_month')}"
                            title="{$planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            aria-label="{$planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if $planGeneratorStore.plan.income_goal_show_emergency_fund_savings_per_month}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                </div>
                <span class="show-print sub-goal-amount">
                    {accounting.formatMoney($planGeneratorStore.plan.income_goal_emergency_fund_savings_per_month)}
                </span>
            </td>
        </tr>
        <tr class="{!$planGeneratorStore.plan.income_goal_show_retirement_savings ? 'hide-goal hide-print' : ''}">
            <td class="sub-goal">Retirement Savings</td>
            <td>
                <div class="hide-print grid-row grid-2 sub-goal-amount">
                    <NumberInput
                        placeholder="Retirement Savings Goal"
                        type="dollar"
                        disabled={!$planGeneratorStore.plan.income_goal_show_retirement_savings}
                        bind:value={$planGeneratorStore.plan.income_goal_retirement_savings}/>
                    <div class="income-goal-button-container">
                        <button
                            class="toggle-{$planGeneratorStore.plan.income_goal_show_retirement_savings ? 'off' : 'on'} income-goal-button button icon-button"
                            on:click|preventDefault="{() => togglePaymentGoalInIncomeGoals('retirement_savings')}"
                            title="{$planGeneratorStore.plan.income_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            aria-label="{$planGeneratorStore.plan.income_goal_show_retirement_savings ? 'Hide Row' : 'Show Row'}"
                            >
                            {#if $planGeneratorStore.plan.income_goal_show_retirement_savings}
                                <FeatherIcon icon="x" />
                            {:else}
                                <FeatherIcon icon="rotate-ccw" />
                            {/if}
                        </button>
                    </div>
                </div>
                <span class="show-print sub-goal-amount">
                    {accounting.formatMoney($planGeneratorStore.plan.income_goal_retirement_savings)}
                </span>
            </td>
        </tr>
        {#each allGoals as goal, index}
            {#if goal.type.name !== 'mindset'}
                <tr class="{!goal.showInPlanIncomeGoals ? 'hide-goal hide-print' : ''}">
                    <td class="sub-goal">{goal.name}</td>
                    <td>
                        <div class="hide-print grid-row grid-2 sub-goal-amount">
                            <NumberInput
                                placeholder="{goal.name}"
                                type="dollar"
                                disabled={!goal.showInPlanIncomeGoals}
                                bind:value={goal.newContribution}/>
                            <div class="income-goal-button-container">
                                <button
                                    class="toggle-{goal.showInPlanIncomeGoals ? 'off' : 'on'} income-goal-button button icon-button"
                                    on:click|preventDefault="{() => toggleGoalInIncomeGoals(index)}"
                                    title="{goal.showInPlanIncomeGoals ? 'Hide Row' : 'Show Row'}"
                                    aria-label="{goal.showInPlanIncomeGoals ? 'Hide Row' : 'Show Row'}"
                                    >
                                    {#if goal.showInPlanIncomeGoals}
                                        <FeatherIcon icon="x" />
                                    {:else}
                                        <FeatherIcon icon="rotate-ccw" />
                                    {/if}
                                </button>
                            </div>
                        </div>
                        <span class="show-print sub-goal-amount">
                            {accounting.formatMoney(goal.newContribution)}
                        </span>
                    </td>
                </tr>
            {/if}
        {/each}
        {#if totalCreditCardDebt > 0}
            <tr class="{!$planGeneratorStore.plan.income_goal_show_debt_crushing_extra ? 'hide-goal hide-print' : ''}">
                <td class="sub-goal">Additional Credit Card Payment</td>
                <td>
                    <div class="hide-print grid-row grid-2 sub-goal-amount">
                        <NumberInput
                            placeholder="Additional Credit Card Payment"
                            type="dollar"
                            disabled={!$planGeneratorStore.plan.income_goal_show_debt_crushing_extra}
                            bind:value={$planGeneratorStore.plan.income_goal_debt_crushing_extra}/>
                        <div class="income-goal-button-container">
                            <button
                                class="toggle-{$planGeneratorStore.plan.income_goal_show_debt_crushing_extra ? 'off' : 'on'} income-goal-button button icon-button"
                                on:click|preventDefault="{() => togglePaymentGoalInIncomeGoals('debt_crushing_extra')}"
                                title="{$planGeneratorStore.plan.income_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.income_goal_show_debt_crushing_extra ? 'Hide Row' : 'Show Row'}"
                                >
                                {#if $planGeneratorStore.plan.income_goal_show_debt_crushing_extra}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    </div>
                    <span class="show-print sub-goal-amount">
                        {accounting.formatMoney($planGeneratorStore.plan.income_goal_debt_crushing_extra)}
                    </span>
                </td>
            </tr>
        {/if}
        {#if wizard.liabilityManager.getByStudentLoanType(['federal']).length > 0}
            <tr class="{!$planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount ? 'hide-goal hide-print' : ''}">
                <td class="sub-goal">Additional Federal Student Loan Payment</td>
                <td>
                    <div class="hide-print grid-row grid-2 sub-goal-amount">
                        <NumberInput
                            placeholder="Additional Federal Student Loan Payment"
                            type="dollar"
                            disabled={!$planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount}
                            bind:value={$planGeneratorStore.plan.income_goal_student_loan_management_federal_amount}/>
                        <div class="income-goal-button-container">
                            <button
                                class="toggle-{$planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount ? 'off' : 'on'} income-goal-button button icon-button"
                                on:click|preventDefault="{() => togglePaymentGoalInIncomeGoals('student_loan_management_federal_amount')}"
                                title="{$planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount ? 'Hide Row' : 'Show Row'}"
                                >
                                {#if $planGeneratorStore.plan.income_goal_show_student_loan_management_federal_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    </div>
                    <span class="show-print sub-goal-amount">
                        {accounting.formatMoney($planGeneratorStore.plan.income_goal_student_loan_management_federal_amount)}
                    </span>
                </td>
            </tr>
        {/if}
        {#if wizard.liabilityManager.getByStudentLoanType(['private']).length > 0}
            <tr class="{!$planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount ? 'hide-goal hide-print' : ''}">
                <td class="sub-goal">Additional Private Student Loan Payment</td>
                <td>
                    <div class="hide-print grid-row grid-2 sub-goal-amount">
                        <NumberInput
                            placeholder="Additional Private Student Loan Payment"
                            type="dollar"
                            disabled={!$planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount}
                            bind:value={$planGeneratorStore.plan.income_goal_student_loan_management_private_amount}/>
                        <div class="income-goal-button-container">
                            <button
                                class="toggle-{$planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount ? 'off' : 'on'} income-goal-button button icon-button"
                                on:click|preventDefault="{() => togglePaymentGoalInIncomeGoals('student_loan_management_private_amount')}"
                                title="{$planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                aria-label="{$planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount ? 'Hide Row' : 'Show Row'}"
                                >
                                {#if $planGeneratorStore.plan.income_goal_show_student_loan_management_private_amount}
                                    <FeatherIcon icon="x" />
                                {:else}
                                    <FeatherIcon icon="rotate-ccw" />
                                {/if}
                            </button>
                        </div>
                    </div>
                    <span class="show-print sub-goal-amount">
                        {accounting.formatMoney($planGeneratorStore.plan.income_goal_student_loan_management_private_amount)}
                    </span>
                </td>
            </tr>
        {/if}
        <tr>
            <th>ANNUAL SALARY GOAL</th>
            <td>{accounting.formatMoney(annualSalaryGoal)}</td>
        </tr>
    </tbody>
</table>

<div class="stop-page-break income-goals">
    <div class="circle">Know Your Worth $</div>
    <div class="circle">Research</div>
    <div class="circle">Set Up A Review With Your Manager</div>
    <div class="circle">Advocate for Yourself</div>
</div>

<TrainerCommentComponent type="{'income_goal_trainer_comments'}" />

<style lang="stylus" scoped>td {
  padding: 8px 20px;
  word-break: break-word;
}
td:nth-child(2) {
  width: 50%;
}
.grid-row {
  margin-bottom: 0;
}
.grid-row.grid-2 {
  grid-template-columns: 5fr 1fr;
}
.grid-row button {
  justify-self: right;
}
.hide-goal {
  opacity: 50%;
  background-color: #f5f5f5;
}
.income-goals {
  align-items: center;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  width: 85%;
  margin: 0 auto;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  table {
    display: block;
    overflow-x: auto;
  }
}
@media screen and (min-width: 481px) {
  .ideal-variable-spend {
    padding-left: 0;
  }
  .sub-goal {
    padding-left: 3rem;
  }
  .sub-goal-amount {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .income-goals {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 360px) {
  .income-goals {
    grid-template-columns: 1fr;
  }
}
@media print {
  td {
    padding: 8px;
  }
  .ideal-variable-spend {
    padding-left: 8px;
  }
  .sub-goal {
    padding-left: 3rem;
  }
  .sub-goal-amount {
    padding-left: 1rem;
  }
}
</style>
