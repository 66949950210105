<script>
    export let src;
    export let imgClass = '';
    export let alt;
</script>

<img 
    class="{imgClass}"
    src="{APP_ROOT}/assets/{src}"
    {alt} />

<style lang="stylus" scoped>.breakeven-pyramid {
  width: 45%;
}
.retirement-image {
  width: 50%;
}
.communication-image {
  margin-bottom: 1rem;
  max-height: 50px;
  max-width: 75px;
}
@media screen and (max-width: 480px) {
  .retirement-image,
  .breakeven-pyramid {
    width: 100%;
  }
}
</style>
