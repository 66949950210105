<script>
    import { planGeneratorStore } from '../../../shared/store';

    import NumberInput from '../../UXElements/NumberInput.svelte';
</script>

<h1 class="page-title">Strategy For Paying Off Credit Card Debt</h1>

<div>
    <p>
        Since you have a good credit score, we have a couple of options for easing your debt burden
        and streamlining your repayment plan. The debt avalanche method involves paying down the
        debt with the highest interest rate first, and you have the ability to significantly lower
        your interest rates and/or bring them down to 0%!
    </p>
</div>

<div>
    <table>
        <thead>
            <tr>
                <th colspan="3">
                    <h3>DEBT REPAYMENT OPTIONS</h3>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Product Type</th>
                <th>Debt Level</th>
                <th>Credit Score Range</th>
            </tr>
            <tr>
                <td>Balance Transfer Card</td>
                <td>&lt $10,000</td>
                <td>680+</td>
            </tr>
            <tr>
                <td>Personal Loan</td>
                <td>> $10,000</td>
                <td>700+</td>
            </tr>
        </tbody>
    </table>
</div>

<div>
    <table>
        <thead>
            <tr>
                <th colspan="3">
                    <h3>WHICH IS BEST FOR YOU?</h3>                
                </th>
            </tr>

        </thead>
        <tbody>
            <tr>
                <th>Product Type</th>
                <th>Pros</th>
                <th>Cons</th>
            </tr>
            <tr>
                <td>Balance Transfer Card</td>
                <td>
                    <ul class='unstyled-list'>
                        <li>- 0% interest for 15-21 months</li>
                        <li>- Low minimum payment</li>
                    </ul>
                </td>
                <td>
                    <ul class='unstyled-list'>
                        <li>- Typically can only transfer $10,000 or less.</li>
                        <li>- Potentially required to pay a balance transfer fee</li>
                        <li>- Cannot transfer debt within the same institution</li>
                        <li>- May need to open more than one card at the same time</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>Personal Loan</td>
                <td>
                    <ul class='unstyled-list'>
                        <li>- Lower your interest rate</li>
                        <li>- Fixed repayment term</li>
                        <li>- Fixed monthly payment</li>
                        <li>- Useful for larger amounts of debt</li>
                    </ul>
                </td>
                <td>
                    <ul class='unstyled-list'>
                        <li>- Interest rate will be more than 0%</li>
                        <li>- Monthly payment may be the same as current minimums</li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="hide-print show-print">
    <ul>
        <li>
            We can explore both options, but I recommend you go with
            <select
                class="form-control hide-print"
                bind:value="{$planGeneratorStore.plan.trainer_recommendation}">
                <option>Please Select</option>
                <option value="Personal Loans">Personal Loans</option>
                <option value="Balance Transfer Cards">Balance Transfer Cards</option>
            </select>
            Option. Let’s review here:
            <ul>
                <li>
                    <a
                        href="https://financialgym.com/bff-approved-personal-loans"
                        target="_blank">
                        Personal Loans
                    </a>
                </li>
                <li>
                    <a
                        href="https://oc.brcclx.com/t/?lid=26680862&cr=30096&last_updated=1628704676"
                        target="_blank">
                        Balance Transfer Cards
                    </a>
                </li>
            </ul>
        </li>
        <li>
            I recommend you select
            <strong>{$planGeneratorStore.plan.trainer_recommendation}</strong>
            and plan to keep your monthly payment at $
            <span class="hide-print">
                <NumberInput additionalClass={true} bind:value={$planGeneratorStore.plan.recommended_monthly_payment} />
            </span>
            <span class="show-print">{$planGeneratorStore.plan.recommended_monthly_payment}</span>
            per month moving forward
        </li>
    </ul>
</div>

<style lang="stylus" scoped>.unstyled-list {
  list-style-type: none;
}
@media screen and (max-width: 480px) {
  table {
    display: block;
    overflow-x: auto;
  }
}
</style>
