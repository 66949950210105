<script>
    import { planGeneratorStore } from '../../shared/store';

    import QuillComponent from '../UXElements/QuillComponent.svelte';

    export let wizard;

    $: lifeRisk = wizard.riskManager.getByType('life');
    $: willRisk = wizard.riskManager.getByType('will');
    $: disabilityRisk = wizard.riskManager.getByType('disability');
    $: rentersRisk = wizard.riskManager.getByType('renters');
    $: homeownersRisk = wizard.riskManager.getByType('homeowners');
</script>

<h1 class="page-title">Risk Management Checklist</h1>

<table class="three-column-table">
    <thead>
        <tr>
            <th>What Does It Do?</th>
            <th>Do I Have It?</th>
            <th>Do I Need It?</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <strong>LIFE INSURANCE</strong>
                <br /><br />Supplemental life insurance helps cover the lost income for those that rely on
                you such as your spouse and/or kids if something were to happen to you.
            </td>
            <td>
                {lifeRisk.possess ? `Yes` : `No`}
                <br />
                {lifeRisk.description}
            </td>
            <td>
                <div class="field hide-print">
                    <select
                        class="form-control"
                        placeholder="Yes/No"
                        bind:value="{$planGeneratorStore.plan.target_life_boolean}"
                        data-cy="risk-management-life-boolean-input">
                        <option value="{null}">Please Select</option>
                        <option value="{true}">Yes</option>
                        <option value="{false}">No</option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_life_boolean ? 'Yes' : 'No'}
                </span>
                <QuillComponent hasToolbar={false} type={'target_life_note'} isRiskManagementChecklist={true}/>
            </td>
        </tr>

        <tr>
            <td>
                <strong>WILL</strong>
                <br /><br />If something were to happen to you, your assets would go to probate, but a will
                allows you to dictate where you'd like your money to go.
            </td>
            <td>
                {willRisk.possess ? `Yes` : `No`}
                <br />
                {willRisk.description}
            </td>
            <td>
                <div class="field hide-print">
                    <select
                        class="form-control"
                        placeholder="Yes/No"
                        bind:value="{$planGeneratorStore.plan.target_will_boolean}"
                        data-cy="risk-management-will-boolean-input">
                        <option value="{null}">Please Select</option>
                        <option value="{true}">Yes</option>
                        <option value="{false}">No</option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_will_boolean ? 'Yes' : 'No'}
                </span>
                <QuillComponent hasToolbar={false} type={'target_will_note'} isRiskManagementChecklist={true}/>
            </td>
        </tr>

        <tr>
            <td>
                <strong>DISABILITY INSURANCE</strong>
                <br /><br />In the event something were to happen to your health, your income could be
                replaced to cover your expenses.
            </td>
            <td>
                {disabilityRisk.possess ? `Yes` : `No`}
                <br />
                {disabilityRisk.description}
            </td>
            <td>
                <div class="field hide-print">
                    <select
                        class="form-control"
                        placeholder="Yes/No"
                        bind:value="{$planGeneratorStore.plan.target_disability_boolean}"
                        data-cy="risk-management-disability-boolean-input">
                        <option value="{null}">Please Select</option>
                        <option value="{true}">Yes</option>
                        <option value="{false}">No</option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_disability_boolean ? 'Yes' : 'No'}
                </span>
                <QuillComponent hasToolbar={false} type={'target_disability_note'} isRiskManagementChecklist={true}/>
            </td>
        </tr>

        <tr>
            <td>
                <strong>RENTERS INSURANCE</strong>
                <br /><br />This can help you replace any of your belongings if they were stolen or
                destroyed in a disaster.
            </td>
            <td>
                {rentersRisk.possess ? `Yes` : `No`}
                <br />
                {rentersRisk.description}
            </td>
            <td>
                <div class="field hide-print">
                    <select
                        class="form-control"
                        placeholder="Yes/No"
                        bind:value="{$planGeneratorStore.plan.target_renters_boolean}"
                        data-cy="risk-management-renters-boolean-input">
                        <option value="{null}">Please Select</option>
                        <option value="{true}">Yes</option>
                        <option value="{false}">No</option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_renters_boolean ? 'Yes' : 'No'}
                </span>
                <QuillComponent hasToolbar={false} type={'target_renters_note'} isRiskManagementChecklist={true}/>
            </td>
        </tr>

        <tr>
            <td>
                <strong>HOMEOWNERS INSURANCE</strong>
                <br /><br />Homeowners insurance covers destruction and damage to your home, protects you against the loss or theft of possessions, and provides personal liability for harm to others that occurs on your property.
            </td>
            <td>
                {homeownersRisk.possess ? `Yes` : `No`}
                <br />
                {homeownersRisk.description}
            </td>
            <td>
                <div class="field hide-print">
                    <select
                        class="form-control"
                        placeholder="Yes/No"
                        bind:value="{$planGeneratorStore.plan.target_homeowners_boolean}"
                        data-cy="risk-management-homeowners-boolean-input">
                        <option value="{null}">Please Select</option>
                        <option value="{true}">Yes</option>
                        <option value="{false}">No</option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_homeowners_boolean ? 'Yes' : 'No'}
                </span>
                <QuillComponent hasToolbar={false} type={'target_homeowners_note'} isRiskManagementChecklist={true}/>
            </td>
        </tr>
    </tbody>
</table>

<style lang="stylus" scoped>@media screen and (max-width: 480px) {
  table {
    display: block;
    overflow-x: auto;
  }
}
</style>
