<script>
    import { planGeneratorStore, intakeDataStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';
    import calculations from '../../helpers/calculations';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';

    export let wizard;
    export let page;
    export let slide;

    let realEstateGoal = {};
    let edited = false;

    let goalType;
    let name;

    if (slide === 'hog') {
        goalType = 'home_savings';
        name = 'Homeownership';
    } else {
        goalType = 'income_producing_property';
        name = 'Income-Producing Property';
    }
    $: {
        realEstateGoal = wizard.goalManager
            .getByTypes([goalType], 'activeGoals')
            .reduce((prev, current) => {
                const inReduceMinMonthlyPayment = calculations.getMinMonthlyPayment(
                    $planGeneratorStore.plan[`${slide}_median_home_price`],
                    $planGeneratorStore.plan[`${slide}_current_mortgage_rate`],
                    $planGeneratorStore.plan[`${slide}_mortgage_term`]
                );

                const inReduceMonthlyPropertyTaxes = calculations.getMonthlyPropertyTaxes(
                    $planGeneratorStore.plan[`${slide}_median_home_price`],
                    $planGeneratorStore.plan[`${slide}_property_tax_rate`]
                );

                const inReduceMonthlyHomeownersInsurance = calculations.getMonthlyHomeownersInsurance(
                    $planGeneratorStore.plan[`${slide}_homeowners_insurance`]
                );

                let inReduceOtherCosts = 0;
                if (slide === 'hog') {
                    inReduceOtherCosts = isNaN($planGeneratorStore.plan[`${slide}_other_costs`]) ? 0
                    : $planGeneratorStore.plan[`${slide}_other_costs`];

                }

                const monthlyAmount = accounting.unformat(
                    accounting.toFixed(
                        parseFloat(inReduceMinMonthlyPayment) +
                            parseFloat(inReduceMonthlyPropertyTaxes) +
                            parseFloat(inReduceMonthlyHomeownersInsurance) +
                            parseFloat(inReduceOtherCosts),
                        2
                    )
                );

                const originalCurrentPlanMonthAmount = parseFloat(current.planMonthAmount);
                const originalPrevPlanMonthAmount = parseFloat(prev.planMonthAmount);

                if (monthlyAmount >= 0) {
                    current.planMonthAmount =
                        Math.round(originalCurrentPlanMonthAmount) === Math.round(monthlyAmount)
                            ? Math.round(monthlyAmount)
                            : Math.round(originalCurrentPlanMonthAmount);
                    prev.planMonthAmount =
                        Math.round(originalPrevPlanMonthAmount) === Math.round(monthlyAmount)
                            ? Math.round(monthlyAmount)
                            : Math.round(originalPrevPlanMonthAmount);
                } else {
                    current.planMonthAmount = 0;
                    prev.planMonthAmount = 0;
                }

                if (edited) {
                    current.planMonthAmount = Math.round(monthlyAmount);
                    prev.planMonthAmount = Math.round(monthlyAmount);
                }

                current.planMonthAmount = isNaN(parseFloat(current.planMonthAmount)) ? 0 : current.planMonthAmount;
                prev.planMonthAmount = isNaN(parseFloat(prev.planMonthAmount)) ? 0 : prev.planMonthAmount;

                edited = false;
                return prev.monthsRemaining < current.monthsRemaining ? prev : current;
            }, {});

        $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
    }

    let currentDebtToIncomeRatio = 0;
    let monthlyOtherCosts = 0;

    $: if(slide === 'hog') {
        currentDebtToIncomeRatio =
        wizard.getDebtToIncomeRatio() === null ? 0 : wizard.getDebtToIncomeRatio();
        monthlyOtherCosts = accounting.unformat(
            accounting.toFixed($planGeneratorStore.plan[`${slide}_other_costs`], 2)
        );
    }
    // Reactive - done with individual lines so each variable doesn't need to be declared before use
    // toFixed to leverage accounting library to round better than built in js rounding
    // then unformat to convert the result from a string to a number
    $: downPayment = accounting.unformat(
        accounting.toFixed($planGeneratorStore.plan[`${slide}_median_home_price`] * 0.2, 2)
    );

    $: closingCosts = accounting.unformat(
        accounting.toFixed($planGeneratorStore.plan[`${slide}_median_home_price`] * 0.03, 2)
    );
    $: contingencyFund = 10000;
    $: upfrontCosts = accounting.unformat(
        accounting.toFixed(downPayment + closingCosts + contingencyFund, 2)
    );

    $: minMonthlyPayment = accounting.unformat(
        accounting.toFixed(
            calculations.getMinMonthlyPayment(
                $planGeneratorStore.plan[`${slide}_median_home_price`],
                $planGeneratorStore.plan[`${slide}_current_mortgage_rate`],
                $planGeneratorStore.plan[`${slide}_mortgage_term`]
            ),
            2
        )
    );
    $: monthlyPropertyTaxes = accounting.unformat(
        accounting.toFixed(
            calculations.getMonthlyPropertyTaxes(
                $planGeneratorStore.plan[`${slide}_median_home_price`],
                $planGeneratorStore.plan[`${slide}_property_tax_rate`]
            ),
            2
        )
    );
    $: monthlyHomeownersInsurance = accounting.unformat(
        accounting.toFixed(
            calculations.getMonthlyHomeownersInsurance(
                $planGeneratorStore.plan[`${slide}_homeowners_insurance`]
            ),
            2
        )
    );

    $: monthlyCosts = accounting.unformat(
        accounting.toFixed(
            minMonthlyPayment +
                monthlyPropertyTaxes +
                monthlyHomeownersInsurance +
                monthlyOtherCosts,
            2
        )
    );

    $: if (page.duplicate) {
        if (!page.id) {
            if (page.data.length === 0) {
                page.data = {
                    name: `${name} Goal - ${parseInt(page.title.split('_')[1]) + 1}`,
                    up_front_costs: parseFloat(upfrontCosts).toFixed(0),
                    down_payment: parseFloat(downPayment).toFixed(0),
                    closing_costs: parseFloat(closingCosts).toFixed(0),
                    contingency_fund: parseFloat(contingencyFund).toFixed(0),
                    monthly_costs: parseFloat(monthlyCosts).toFixed(0),
                    min_monthly_payment: parseFloat(minMonthlyPayment).toFixed(0),
                    monthly_property_taxes: parseFloat(monthlyPropertyTaxes).toFixed(0),
                    monthly_homeowners_insurance: parseFloat(monthlyHomeownersInsurance).toFixed(0)
                };

                page.data[`${slide}_median_home_price`] = parseFloat($planGeneratorStore.plan[`${slide}_median_home_price`]).toFixed(0);
                page.data[`${slide}_current_mortgage_rate`] = parseFloat($planGeneratorStore.plan[`${slide}_current_mortgage_rate`]).toFixed(2);
                page.data[`${slide}_mortgage_term`] = parseFloat($planGeneratorStore.plan[`${slide}_mortgage_term`]);
                page.data[`${slide}_property_tax_rate`] = parseFloat($planGeneratorStore.plan[`${slide}_property_tax_rate`]).toFixed(2);
                page.data[`${slide}_homeowners_insurance`] = parseFloat($planGeneratorStore.plan[`${slide}_homeowners_insurance`]).toFixed(0);

                if (slide === 'hog') {
                    page.data[`${slide}_other_costs`] = parseFloat($planGeneratorStore.plan[`${slide}_other_costs`]).toFixed(0);

                    page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"Homeownership is a great goal and can help to build wealth over time, but there are a lot of things to consider before making this decision. We like to break the costs of homeownership into two sections, upfront costs and monthly costs. In order to calculate these costs, we first need to make a few assumptions:\n"}]});

                    page.data[`${page.title}_quill_text_1`] = JSON.stringify({"ops":[{"insert":"Homeownership Tips:"},{"attributes":{"header":3},"insert":"\n"},{"insert":"At a minimum, you need to begin saving the difference between your current housing payment and the estimated future monthly payment. This will ensure that you can support making this higher payment without changing your lifestyle too much."},{"attributes":{"list":"bullet"},"insert":"\n"},{"insert":"If this goal is more than 2 years away, invest in a brokerage account with the appropriate asset allocation!"},{"attributes":{"indent":1,"list":"bullet"},"insert":"\n"},{"insert":"Maintain a credit score of at least 750 to get the best possible rates."},{"attributes":{"list":"bullet"},"insert":"\n"},{"insert":"Keep your debt to income ratio at 43% or less. Based on your current income and debt obligations, your debt to income ratio is 39.27%"},{"attributes":{"list":"bullet"},"insert":"\n"}]});
                } else {
                    page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"You are considering purchasing an investment property. The first thing to remember when evaluating potential properties is the Rule of 1%.\nThe Rule of 1% states a property is worth investing in if you can expect to rent it for at least 1% of the total cost. For example, if a house costs $100,000, you should be able to easily rent it for at least $1,000 a month."},{"attributes":{"list":"bullet"},"insert":"\n"},{"insert":"Here’s a rough cost breakdown for your rental property purchase:"},{"attributes":{"list":"bullet"},"insert":"\n"}]});

                    page.data[`${page.title}_quill_text_1`] = JSON.stringify({"ops":[{"insert":"Tips:"},{"attributes":{"header":3},"insert":"\n"},{"insert":"Invest through a turnkey real estate investment company in order to eliminate the amount of work required to be a landlord."},{"attributes":{"list":"bullet"},"insert":"\n"},{"insert":"These costs should be no more than 10%-12%"},{"attributes":{"list":"bullet"},"insert":"\n"},{"insert":"Helpful Podcasts:"},{"attributes":{"header":3},"insert":"\n"},{"attributes":{"color":"var(--color-green-dark)","link":"http://financially-blonde.com/income-producing-property-investing/"},"insert":"http://financially-blonde.com/income-producing-property-investing/"},{"attributes":{"list":"bullet"},"insert":"\n"},{"attributes":{"color":"var(--color-green-dark)","link":"https://www.listenmoneymatters.com/roofstock-review/"},"insert":"https://www.listenmoneymatters.com/roofstock-review/"},{"attributes":{"list":"bullet"},"insert":"\n"},{"attributes":{"color":"var(--color-green-dark)","link":"https://www.listenmoneymatters.com/rental-property-lessons/"},"insert":"https://www.listenmoneymatters.com/rental-property-lessons/"},{"attributes":{"list":"bullet"},"insert":"\n"}]});
                }

            }
        }
    }

    const calculateClosingCosts = () => {
        const homePrice = Number.isFinite(parseFloat(page.data[`${slide}_median_home_price`])) ? parseFloat(page.data[`${slide}_median_home_price`]) : 0;

        page.data.closing_costs = homePrice * .03;
    }

    const calculateUpfrontCosts = () => {
        const downPayment = Number.isFinite(parseFloat(page.data.down_payment)) ? parseFloat(page.data.down_payment) : 0;
        const closingCosts = Number.isFinite(parseFloat(page.data.closing_costs)) ? parseFloat(page.data.closing_costs) : 0;
        const contingencyFund = Number.isFinite(parseFloat(page.data.contingency_fund)) ? parseFloat(page.data.contingency_fund) : 0;

        page.data.up_front_costs = accounting.toFixed(downPayment + closingCosts + contingencyFund, 2);
    }

    const calculateMortgageRate = () => {
        const homePrice = Number.isFinite(parseFloat(page.data[`${slide}_median_home_price`])) ? parseFloat(page.data[`${slide}_median_home_price`]) : 0;
        const mortgageRate = Number.isFinite(parseFloat(page.data[`${slide}_current_mortgage_rate`])) ? parseFloat(page.data[`${slide}_current_mortgage_rate`]) : 0;
        const mortgageTerm = Number.isFinite(parseFloat(page.data[`${slide}_mortgage_term`])) ? parseFloat(page.data[`${slide}_mortgage_term`]) : 0;
        const downPayment = Number.isFinite(parseFloat(page.data.down_payment)) ? parseFloat(page.data.down_payment) : 0;

        page.data.min_monthly_payment = accounting.unformat(
            accounting.toFixed(
                calculations.getMinMonthlyPayment(
                    homePrice,
                    mortgageRate,
                    mortgageTerm,
                    downPayment
            ), 2)
        );
    }

    const calculatePropertyTaxes = () => {
        const homePrice = Number.isFinite(parseFloat(page.data[`${slide}_median_home_price`])) ? parseFloat(page.data[`${slide}_median_home_price`]) : 0;
        const taxRate = Number.isFinite(parseFloat(page.data[`${slide}_property_tax_rate`])) ? parseFloat(page.data[`${slide}_property_tax_rate`]) : 0;

        page.data.monthly_property_taxes = accounting.unformat(
            accounting.toFixed(
                calculations.getMonthlyPropertyTaxes(
                    homePrice,
                    taxRate
                ),
                2
            )
        );
    }

    const calculateMonthlyHomeownersInsurance = () => {
        const annualHomeownersInsurance = Number.isFinite(parseFloat(page.data[`${slide}_homeowners_insurance`])) ? parseFloat(page.data[`${slide}_homeowners_insurance`]) : 0;

        page.data.monthly_homeowners_insurance = accounting.unformat(
            accounting.toFixed(
                calculations.getMonthlyHomeownersInsurance(
                    annualHomeownersInsurance
                ),
                2
            )
        );
    }

    const calculateMonthlyCosts = () => {
        const monthlyPayment = Number.isFinite(parseFloat(page.data.min_monthly_payment)) ? parseFloat(page.data.min_monthly_payment) : 0;
        const propertyTaxes = Number.isFinite(parseFloat(page.data.monthly_property_taxes)) ? parseFloat(page.data.monthly_property_taxes) : 0;
        const homeownersInsurance = Number.isFinite(parseFloat(page.data.monthly_homeowners_insurance)) ? parseFloat(page.data.monthly_homeowners_insurance) : 0;

        let otherCosts = 0;
        if(slide === 'hog') {
            otherCosts = Number.isFinite(parseFloat(page.data[`${slide}_other_costs`])) ? parseFloat(page.data[`${slide}_other_costs`]) : 0;
        }

        page.data.monthly_costs = accounting.unformat(
            accounting.toFixed(
                monthlyPayment +
                propertyTaxes +
                homeownersInsurance +
                otherCosts,
                2
            )
        );
    }

    $: if (page.data) {
        calculateClosingCosts();
        calculateUpfrontCosts();
        calculateMortgageRate();
        calculatePropertyTaxes();
        calculateMonthlyHomeownersInsurance();
        calculateMonthlyCosts();
    }
</script>

<h1 class="page-title">
    {#if !page.duplicate}
        {name} Goal
    {:else}
        <div class="input-group hide-print">
            <input type="text" placeholder="Name" bind:value={page.data.name} />
        </div>
        <span class="show-print">{page.data.name}</span>
    {/if}
</h1>

{#if !page.duplicate}
    {#if slide === 'hog'}
        <p>
            Homeownership is a great goal and can help to build wealth over time, but there are a lot of
            things to consider before making this decision. We like to break the costs of homeownership into
            two sections, upfront costs and monthly costs. In order to calculate these costs, we first need
            to make a few assumptions:
        </p>
    {:else}
        <p>
            You are considering purchasing an investment property. The first thing to remember when
            evaluating potential properties is the Rule of 1%.
        </p>

        <ul>
            <li>
                The Rule of 1% states a property is worth investing in if you can expect to rent it for at
                least 1% of the total cost. For example, if a house costs $100,000, you should be able to
                easily rent it for at least $1,000 a month.
            </li>
            <li>Here’s a rough cost breakdown for your rental property purchase:</li>
        </ul>
    {/if}
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_0" />
{/if}

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">Home Purchase Assumptions</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Home Median Price</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{$planGeneratorStore.plan[`${slide}_median_home_price`]}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney($planGeneratorStore.plan[`${slide}_median_home_price`])}
                    </span>
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data[`${slide}_median_home_price`]}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data[`${slide}_median_home_price`])}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Current Mortgage Rate</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                placeholder="3.75"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{$planGeneratorStore.plan[`${slide}_current_mortgage_rate`]}" />
                            <div class="append">
                                %
                            </div>
                        </div>
                    </div>
                    <span class="show-print">
                        {!$planGeneratorStore.plan[`${slide}_current_mortgage_rate`] ? '' : `${$planGeneratorStore.plan[`${slide}_current_mortgage_rate`]}%`}
                    </span>
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                placeholder="3.75"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data[`${slide}_current_mortgage_rate`]}" />
                            <div class="append">
                                %
                            </div>
                        </div>
                    </div>
                    <span class="show-print">
                        {!page.data[`${slide}_current_mortgage_rate`] ? '' : `${page.data[`${slide}_current_mortgage_rate`]}%`}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Mortgage Term</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                type="number"
                                class="form-control"
                                step="0.01"
                                placeholder="30"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{$planGeneratorStore.plan[`${slide}_mortgage_term`]}" />
                            <span class="append"></span>
                        </div>
                    </div>
                    <span class="show-print">
                        {!$planGeneratorStore.plan[`${slide}_mortgage_term`] ? '' : $planGeneratorStore.plan[`${slide}_mortgage_term`]}
                    </span>
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                type="number"
                                class="form-control"
                                step="0.01"
                                placeholder="30"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data[`${slide}_mortgage_term`]}" />
                            <span class="append"></span>
                        </div>
                    </div>
                    <span class="show-print">
                        {!page.data[`${slide}_mortgage_term`] ? '' : page.data[`${slide}_mortgage_term`]}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Property Tax Rate</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                class="form-control hide-print"
                                type="number"
                                step="0.01"
                                placeholder="1.5"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{$planGeneratorStore.plan[`${slide}_property_tax_rate`]}" />
                            <div class="append">
                                %
                            </div>
                        </div>
                    </div>
                    <span class="show-print">
                        {!$planGeneratorStore.plan[`${slide}_property_tax_rate`] ? '' : `${$planGeneratorStore.plan[`${slide}_property_tax_rate`]}%`}
                    </span>
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend"></span>
                            <input
                                class="form-control hide-print"
                                type="number"
                                step="0.01"
                                placeholder="1.5"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data[`${slide}_property_tax_rate`]}" />
                            <div class="append">
                                %
                            </div>
                        </div>
                    </div>
                    <span class="show-print">
                        {!page.data[`${slide}_property_tax_rate`] ? '' : `${page.data[`${slide}_property_tax_rate`]}%`}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Homeowners Insurance</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                placeholder="1500"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{$planGeneratorStore.plan[`${slide}_homeowners_insurance`]}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney($planGeneratorStore.plan[`${slide}_homeowners_insurance`])}
                    </span>
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                placeholder="1500"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data[`${slide}_homeowners_insurance`]}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data[`${slide}_homeowners_insurance`])}
                    </span>
                {/if}
            </td>
        </tr>
    </tbody>
</table>

<br />

<table class="two-column-table">
    <tbody>
        <tr>
            <td>
                <strong>UPFRONT COSTS</strong>
            </td>
            <td>
                {#if !page.duplicate}
                    <strong>{accounting.formatMoney(upfrontCosts)}</strong>
                {:else}
                    <strong>{accounting.formatMoney(page.data.up_front_costs)}</strong>
                {/if}
            </td>
        </tr>
        <tr>
            <td>{#if !page.duplicate}20% {/if}Down Payment</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(downPayment)}
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                step="0.01"
                                type="number"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data.down_payment}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data.down_payment)}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>3% Closing Costs</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(closingCosts)}
                {:else}
                    {accounting.formatMoney(page.data.closing_costs)}
                {/if}
            </td>
        </tr>
        <tr>
            <td>Contingency Fund</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(contingencyFund)}
                {:else}
                    <div class="hide-print">
                        <div class="number">
                            <span class="prepend">
                                $
                            </span>
                            <input
                                class="form-control"
                                step="0.01"
                                type="number"
                                on:keyup={(e)=>{edited = true}}
                                bind:value="{page.data.contingency_fund}" />
                        </div>
                    </div>
                    <span class="show-print">
                        {accounting.formatMoney(page.data.contingency_fund)}
                    </span>
                {/if}
            </td>
        </tr>
    </tbody>
</table>

<br />

<table class="two-column-table">
    <tbody>
        <tr>
            <td>
                <strong>MONTHLY COSTS</strong>
            </td>
            <td>
                {#if !page.duplicate}
                    <strong>{accounting.formatMoney(monthlyCosts)}</strong>
                {:else}
                    <strong>{accounting.formatMoney(page.data.monthly_costs)}</strong>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Mortgage Payment</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(minMonthlyPayment)}
                {:else}
                    {accounting.formatMoney(page.data.min_monthly_payment)}
                {/if}
            </td>
        </tr>
        <tr>
            <td>Monthly Property Taxes</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(monthlyPropertyTaxes)}
                {:else}
                    {accounting.formatMoney(page.data.monthly_property_taxes)}
                {/if}
            </td>
        </tr>
        <tr>
            <td>Monthly Homeowners Insurance</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(monthlyHomeownersInsurance)}
                {:else}
                    {accounting.formatMoney(page.data.monthly_homeowners_insurance)}
                {/if}
            </td>
        </tr>
        {#if slide === 'hog'}
            <tr>
                <td>Other Costs</td>
                <td>
                    {#if !page.duplicate}
                        <div class="hide-print">
                            <div class="number">
                                <span class="prepend">
                                    $
                                </span>
                                <input
                                    class="form-control"
                                    step="0.01"
                                    type="number"
                                    on:keyup={(e)=>{edited = true}}
                                    bind:value="{$planGeneratorStore.plan[`${slide}_other_costs`]}" />
                            </div>
                        </div>
                        <span class="show-print">
                            {accounting.formatMoney($planGeneratorStore.plan[`${slide}_other_costs`])}
                        </span>
                    {:else}
                        <div class="hide-print">
                            <div class="number">
                                <span class="prepend">
                                    $
                                </span>
                                <input
                                    class="form-control"
                                    type="number"
                                    step="0.01"
                                    on:keyup={(e)=>{edited = true}}
                                    bind:value="{page.data[`${slide}_other_costs`]}" />
                            </div>
                        </div>
                        <span class="show-print">
                            {accounting.formatMoney(page.data[`${slide}_other_costs`])}
                        </span>
                    {/if}
                </td>
            </tr>
        {/if}
    </tbody>
</table>


{#if !page.duplicate}
    {#if slide === 'hog'}
        <h3>Homeownership Tips:</h3>
        <ul style="list-style-type: disc !important;">
            <li>
                At a minimum, you need to begin saving the difference between your current housing
                payment and the estimated future monthly payment. This will ensure that you can support
                making this higher payment without changing your lifestyle too much.
                <ul>
                    <li>
                        If this goal is more than 2 years away, invest in a brokerage account with the
                        appropriate asset allocation!
                    </li>
                </ul>
            </li>

            <li>Maintain a credit score of at least 750 to get the best possible rates.</li>

            <li>
                Keep your debt to income ratio at 43% or less. Based on your current income and debt
                obligations, your debt to income ratio is {currentDebtToIncomeRatio.toFixed(2)}%
            </li>
        </ul>
    {:else}
        <h3>Tips:</h3>
        <ul>
            <li>
                Invest through a turnkey real estate investment company in order to eliminate the amount
                of work required to be a landlord.
            </li>
            <li>These costs should be no more than 10%-12%</li>
        </ul>

        <h3>Helpful Podcasts:</h3>
        <ul class="links">
            <li>
                <a
                    href="http://financially-blonde.com/income-producing-property-investing/"
                    target="_blank">
                    http://financially-blonde.com/income-producing-property-investing/
                </a>
            </li>
            <li>
                <a href="https://www.listenmoneymatters.com/roofstock-review/" target="_blank">
                    https://www.listenmoneymatters.com/roofstock-review/
                </a>
            </li>
            <li>
                <a href="https://www.listenmoneymatters.com/rental-property-lessons/" target="_blank">
                    https://www.listenmoneymatters.com/rental-property-lessons/
                </a>
            </li>
        </ul>
    {/if}
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_1" />
{/if}

<style lang="stylus" scoped>.number {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}
.number .prepend {
  align-self: center;
  padding-right: 0.5em;
  text-align: right;
}
.number .append {
  align-self: center;
  padding-left: 0.5em;
  text-align: left;
}
@media screen and (max-width: 480px) {
  table {
    display: block;
    overflow-x: auto;
  }
  td {
    padding: 8px;
  }
}
</style>
