<script>
    import { push, link, location } from 'svelte-spa-router';
    import { loggedIn, trainerDataStore } from '../../shared/store';
    import ProfileActions from '../Mobile/ProfileActions.svelte';
    import ClientSummary from '../Mobile/ClientSummary.svelte';
    import Alerts from '../Mobile/Alerts.svelte';
    import PlanSideBar from './Plan.svelte';
    import IntakeSidebar from './Intake.svelte';
    import NoteButton from '../../components/Notes/Button.svelte';

    export let logOut;

    $: trainerName = $trainerDataStore.first_name + ' ' + $trainerDataStore.last_name;
</script>

<section class="sidebar overview">
    <header class="sidebar-header page-view">

        <ProfileActions {logOut} />

        <div class="container flex-wrapper w90">

            {#if $loggedIn }
                <!--
            <a class="mobile ico-alert new-alert" href="alerts">
                <span class="screen-reader-text">See all alerts</span>
            </a>
                -->
            {/if}

            <h1 class="site-title">
                <a 
                    href="/"
                    title="Home"
                    use:link >
                    Financial Gym
                </a>
            </h1>

            {#if $loggedIn }
                <button 
                    class="mobile button open-link open-profile icon-button" 
                    id="mobProfileLink"
                    title="open menu"
                    aria-label="open menu"
                    rel="mobProfile" >
                    <img
                        class="image-round image-round-xsmall"
                        src="{$trainerDataStore.users.length > 0 ? $trainerDataStore.users[0].gravatar_url : `${S3BUCKET_URL}/logo-mark.jpg`}"
                        alt="{trainerName}" />
                </button>
            {/if}
        </div>
    </header>

    <!-- <Alerts /> -->

    <ClientSummary />

    {#if $location.includes('intake') || $location.includes('plan')}
        <NoteButton />
    {/if}

    {#if $location.includes('intake')}
        <IntakeSidebar />
    {:else if $location.includes('plan')}
        <PlanSideBar />
    {/if}

</section>

<style lang="stylus" scoped>img {
  border-color: var(--color-white);
  border-style: solid;
  border-width: 2px;
}
</style>
