<script>
    import goalSupplementaryInfo from '../../../helpers/goal_supplementary_information';
    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let type
    export let goal;
    export let index;
</script>

{#if goalSupplementaryInfo[type]}
    {#each goalSupplementaryInfo[type] as info}
        {#if info.type !== 'checkbox'}
            <div class="field">
                <label for="{info.name}-{index}">{info.name.split('_').join(' ')}</label>
                 {#if info.type === 'text'}
                    <input id="{info.name}-{index}" class="form-control" type="text" bind:value={goal[info.name]}/>
                {:else if info.type === 'number'}
                    <NumberInput id="{info.name}-{index}" bind:value={goal[info.name]} />
                {/if}
            </div>
        {:else if info.type === 'checkbox'}
            <div class="field checkboxes">
                <label class="check-label">
                    <input
                        type="checkbox"
                        bind:checked="{goal[info.name]}"
                        data-cy="goal-include-{goal.id}" />
                    <span>
                        {#if info.name === 'general_travel'}
                            Categorize for Generic Annual Travel Budget
                        {:else}
                            {info.name.split('_').join(' ')}
                        {/if}
                    </span>
                </label>
            </div>
        {/if}
    {/each}
{/if}

<style type="stylus">label {
  text-transform: capitalize;
}
</style>
