<script>
    import { createEventDispatcher } from 'svelte';

    import { intakeDataStore } from '../../../shared/store';
    import * as api from '../../../shared/api_access';
    import * as time_frequencies from '../../../helpers/time_frequencies';
    import * as accountTypes from '../../../helpers/account_types';

    import ALFormComponent from './ALFormComponent.svelte';

    export let type = null;
    export let category = null;

    const dispatch = createEventDispatcher();

    const excludedFields = ['id', 'client', 'category', 'date_created', 'new_payment'];

    const assetStringFields = ['name'];
    const assetNumberFields = [
        'balance',
        'interest',
        'contribution',
        'employer_contribution',
        'new_contribution',
        'target_balance',
        'percent_owned',
        'annual_homeowners_insurance',
        'property_tax',
        'rental_income'
    ];
    const assetSelectFields = [
        'investment_type',
        'frequency',
        'contribution_type',
        'employer_contribution_type'
    ];
    const assetBooleanFields = [
        'previous'
    ];

    const liabilityStringFields = ['name'];
    const liabilityNumberFields = [
        'original_balance',
        'balance',
        'interest',
        'min_payment',
        'old_payment',
        'credit_limit'
    ];
    const liabilitySelectFields = ['student_loan_type'];
    const liabilityBooleanFields = ['deferred', 'paid_in_full'];

    let accounts = [];

    const addItem = () => {
        if (type === 'assets') {
            $intakeDataStore[type].push({
                category,
                investment_type: '',
                name: '',
                balance: null,
                interest: null,
                contribution: null,
                contribution_type: '',
                employer_contribution: null,
                employer_contribution_type: '',
                frequency: '',
                new_contribution: null,
                target_balance: null,
                percent_owned: null,
                annual_homeowners_insurance: null,
                property_tax: null,
                rental_income: null,
                previous: false
            });
        } else if (type === 'liabilities') {
            $intakeDataStore[type].push({
                category,
                student_loan_type: '',
                name: '',
                original_balance: null,
                balance: null,
                interest: null,
                min_payment: null,
                old_payment: null,
                credit_limit: null,
                deferred: false,
                paid_in_full: false
            });
        }
        $intakeDataStore[type] = [...$intakeDataStore[type]]; // This must stay to invoke reactivity
        accounts = $intakeDataStore[type].filter(account => account.category === category);
        accounts = accounts.reverse();
    };

    const removeItem = async account => {
        // This is being done to due to the reversed ALRow copy of assets/liabilities
        // not haveing the same index as the normal ordered store
        // This is kinda hacky/slow
        for (let index = 0; index < $intakeDataStore[type].length; index += 1) {
            if (account === $intakeDataStore[type][index]) {
                $intakeDataStore[type].splice(index, 1);
            }
        }
        dispatch('subtractCategory', { categoryName: account.category });
        accounts = $intakeDataStore[type].filter(accountItem => accountItem.category === category);
        accounts = accounts.reverse();
        if (account.id) {
            await api.remove(account.id, type);
        }
    };

    // Get object keys as an array
    const getAccountKeys = account => {
        return Object.keys(account).filter(key => {
            if (!excludedFields.includes(key)) {
                // Assets
                if (account.category === 'checking') {
                    if (
                        key === 'contribution' ||
                        key === 'investment_type' ||
                        key === 'employer_contribution' ||
                        key === 'employer_contribution_type' ||
                        key === 'frequency' ||
                        key === 'new_contribution' ||
                        key === 'target_balance' ||
                        key === 'annual_homeowners_insurance' ||
                        key === 'property_tax' ||
                        key === 'rental_income' ||
                        key === 'percent_owned' ||
                        key === 'previous' ||
                        key === 'contribution_type'
                    ) {
                        return false;
                    }
                }
                if (account.category === 'savings') {
                    if (
                        key === 'investment_type' ||
                        key === 'employer_contribution' ||
                        key === 'employer_contribution_type' ||
                        key === 'frequency' ||
                        key === 'new_contribution' ||
                        key === 'target_balance' ||
                        key === 'annual_homeowners_insurance' ||
                        key === 'property_tax' ||
                        key === 'rental_income' ||
                        key === 'percent_owned' ||
                        key === 'previous'
                    ) {
                        return false;
                    }
                }
                if (account.category === 'investment') {
                    if (
                        key === 'new_contribution' ||
                        key === 'target_balance' ||
                        key === 'annual_homeowners_insurance' ||
                        key === 'property_tax' ||
                        key === 'rental_income' ||
                        key === 'percent_owned' ||
                        key === 'frequency'
                    ) {
                        return false;
                    }
                }
                if (account.category === 'real_estate') {
                    if (
                        key === 'investment_type' ||
                        key === 'employer_contribution' ||
                        key === 'employer_contribution_type' ||
                        key === 'new_contribution' ||
                        key === 'target_balance' ||
                        key === 'contribution' ||
                        key === 'frequency' ||
                        key === 'previous' ||
                        key === 'contribution_type'
                    ) {
                        return false;
                    }
                }
                if (account.category === 'other') {
                    if (key === 'investment_type') {
                        return false;
                    }
                }

                // Liabilities
                if (account.category !== 'credit_card' && account.category !== 'charge_card') {
                    if (key === 'credit_limit' ||
                        key === 'paid_in_full'
                    ) {
                        return false;
                    }
                } else {
                    if (key === 'original_balance') {
                        return false;
                    }
                }
                if (account.category === 'charge_card') {
                    if (key === 'credit_limit') {
                        return false;
                    }
                }
                if (account.category !== 'student_loan') {
                    if (key === 'student_loan_type' ||
                        key === 'deferred'
                    ) {
                        return false;
                    }
                }
                if (key === 'old_payment' ||
                    key === 'new_contribution' ||
                    key === 'target_balance'
                ) {
                    return false;
                }
                return true;
            }
        });
    };

    // Get a message the input needs to show
    const getMessage = (key, account) => {
        if (
            key === 'min_payment' &&
            (account[key] === undefined || account[key] === null || account[key] === 0 ) &&
            account['paid_in_full'] === false
        ) {
            let message = 'Math may be incorrect if a minimum payment is not entered or zero';
            if (account.category === 'credit_card' || account.category === 'charge_card') {
                message += ' and the card is not paid in full each month';
            }
            return message;
        }
    }

    // Identify the input type
    const inputTypeAssignment = key => {
        if (type === 'assets') {
            if (assetStringFields.includes(key)) {
                return 'text';
            }
            if (assetNumberFields.includes(key)) {
                return 'number';
            }
            if (assetSelectFields.includes(key)) {
                return 'select';
            }
            if (assetBooleanFields.includes(key)) {
                return 'boolean';
            }
        } else if (type === 'liabilities') {
            if (liabilityStringFields.includes(key)) {
                return 'text';
            }
            if (liabilityNumberFields.includes(key)) {
                return 'number';
            }
            if (liabilityBooleanFields.includes(key)) {
                return 'boolean';
            }
            if (liabilitySelectFields.includes(key)) {
                return 'select';
            }
        }
    };

    // Returns options for a select input
    const getSelectOptions = key => {
        // Assets
        if (key === 'investment_type') {
            return accountTypes.investmentTypes;
        }
        if (key === 'frequency') {
            return time_frequencies.timeFrequencies;
        }
        if (key === 'contribution_type') {
            return accountTypes.contributionTypes;
        }
        if (key === 'employer_contribution_type') {
            return accountTypes.contributionTypes;
        }
        // Liabilities
        if (key === 'student_loan_type') {
            return accountTypes.studentLoanTypes;
        }
        return null;
    };

    $: {
        accounts = $intakeDataStore[type].filter(account => account.category === category);
        accounts = accounts.reverse();
    }
</script>

<h3 class="page-title">
    <button class="button small primary" on:click="{() => addItem()}" title="Add">
        <!-- Title might be a lighthouse check thing? else not needed -->
        Add <em>{category.split('_').join(' ')}</em> Account
    </button>
</h3>
{#each accounts as account, index}
    <h4>{account.name}</h4>
    <form>
        {#each getAccountKeys(account) as key}
            <!-- Assets or Liabilities -->
            {#if type === 'assets' || type === 'liabilities'}
                {#if category !== 'investment'}
                    {#if category === 'real_estate' && key === 'balance'}
                        <ALFormComponent
                            label="Current Market Value"
                            message={getMessage(key, account)}
                            inputType="{inputTypeAssignment(key)}"
                            bind:value="{account[key]}"
                            selectOptions="{getSelectOptions(key)}"
                            {index} />
                    {:else}
                        <ALFormComponent
                            label="{key}"
                            message={getMessage(key, account)}
                            inputType="{inputTypeAssignment(key)}"
                            bind:value="{account[key]}"
                            selectOptions="{getSelectOptions(key)}"
                            {index} />
                    {/if}
                {:else}
                    <!-- Needed to not render employer_contribution ): -->
                    {#if key === 'employer_contribution' || key === 'employer_contribution_type'}
                        {#if account.investment_type.includes('401') || account.investment_type.includes('403') ||
                            account.investment_type.includes('tsp')  || account.investment_type.includes('457b') || 
                            account.investment_type.includes('simple')}
                            <ALFormComponent
                                label="{key.split('_').join(' ')}"
                                message={getMessage(key, account)}
                                inputType="{inputTypeAssignment(key)}"
                                bind:value="{account[key]}"
                                selectOptions="{getSelectOptions(key)}"
                                {index} />
                        {/if}
                    {:else}
                        <ALFormComponent
                            label="{key.split('_').join(' ')}"
                            message={getMessage(key, account)}
                            inputType="{inputTypeAssignment(key)}"
                            bind:value="{account[key]}"
                            selectOptions="{getSelectOptions(key)}"
                            {index} />
                    {/if}
                {/if}
                <!-- Error -->
            {:else}{'ERROR'}{/if}
        {/each}

        <!-- Remove Button -->
        <button class="button small secondary" on:click|preventDefault="{async () => removeItem(account)}">
            Delete
        </button>
    </form>
{/each}

<style lang="stylus" scoped>h3.page-title {
  text-align: center;
}
.secondary {
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.secondary:hover {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
}
.page-title em {
  text-transform: capitalize;
}
form {
  border-bottom: 1px solid var(--color-gray-light);
  padding-bottom: 1em;
}
</style>
