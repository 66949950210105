<script>
    import { afterUpdate, onDestroy } from "svelte";
    import Quill from 'quill';

    import { planGeneratorStore } from '../../shared/store';

    export let type;
    export let data = '';
    export let hasToolbar = true;
    export let isRiskManagementChecklist = false;

    let { client } = $planGeneratorStore.plan;
    let quill;
    let initLoad = true;
    let getPlanLoad = true;

    const icons = Quill.import('ui/icons');
    $: typeId = type.split('_').join('-');

    let toolbar = [
        [{header: 1}, {header: 2}, { header: 3 }, { header: 4 }, "blockquote", "link" ],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
    ];

    icons.header[1] = "<b>H1</b>";
    icons.header[2] = "<b>H2</b>";
    icons.header[3] = "<b>H3</b>";
    icons.header[4] = "<b>H4</b>";

    afterUpdate(() => {
        const container = document.getElementById(`editor-${typeId}`);
        // so there aren't multiple tool bars on quill
        if (container && quill === undefined) {
            toolbar = hasToolbar ? toolbar : false;
            quill = new Quill(container, {
                modules: {
                    toolbar
                },
                theme: "snow",
                placeholder: "Type something...",
            });

            quill.on('text-change', quillTextChange);
        }
    });

    onDestroy(() => {
        if (quill) {
            quill.off('text-change', quillTextChange);
        }
    });

    const quillTextChange = (delta, oldDelta, source, bumped) => {
        let quillContent = JSON.stringify(quill.getContents());

        if (!type.includes('quill')) {
            if (isNaN(parseFloat(typeId))) {
                $planGeneratorStore.plan[type] = quillContent;
            } else {
                if (bumped) {
                    if (JSON.stringify(delta) !== JSON.stringify(oldDelta)) {
                        quillContent = JSON.stringify(delta);
                        quill.setContents(delta);
                    }
                }

                $planGeneratorStore.pages[parseFloat(type)].data = quillContent;
            }
        } else {
            // Handles Duplicate Pages
            const page = $planGeneratorStore.pages.find(page => 
                page.title === type.split('_').slice(0, 2).join('_')
            );

            page.data[type] = quillContent;
        }

        const showPrintContent = document.getElementById(`show-print-editor-${typeId}`)
        showPrintContent.innerHTML = quill.root.innerHTML;
    }

    const setInitQuill = () => {
        if (!type.includes('quill')) {
            if (isNaN(parseFloat(typeId))) {
                if ($planGeneratorStore.plan[type]) {
                    if ($planGeneratorStore.plan[type][0] === '[' || $planGeneratorStore.plan[type][0] === '{') {
                        quill.setContents(JSON.parse($planGeneratorStore.plan[type]).ops);
                    } else if ($planGeneratorStore.plan[type][0] === '<') {
                        quill.clipboard.dangerouslyPasteHTML($planGeneratorStore.plan[type]);
                    } else {
                        quill.setContents([{insert: $planGeneratorStore.plan[type]}]);
                    }
                }
            }

            if (!isNaN(parseFloat(typeId))) {
                if ($planGeneratorStore.pages[parseFloat(type)].data[0] === '[' || $planGeneratorStore.pages[parseFloat(type)].data[0] === '{' ) {
                    quill.setContents(JSON.parse($planGeneratorStore.pages[parseFloat(type)].data).ops);
                } else {
                    quill.setContents([{insert: data}]);
                }
            }
        } else {
            // Handles Duplicate Pages
            const page = $planGeneratorStore.pages.find(page => 
                page.title === type.split('_').slice(0, 2).join('_')
            );
            
            quill.setContents(JSON.parse(page.data[type]));
        }

        quillTextChange();
        return false;
    }

    let bumped = false;

    // A saved plan
    $: if ($planGeneratorStore.plan.id && quill && getPlanLoad) {
        getPlanLoad = setInitQuill();
    }

    // Not a saved Plan
    $: if (!$planGeneratorStore.plan.id && quill && initLoad) {
        initLoad = setInitQuill();
    }

    // Handles Extra Pages
    $: if (!isNaN(parseFloat(typeId)) && quill) {
        const gotContents = JSON.stringify(quill.getContents());

        // in case people be playing around in ryan's feature too much
        // backwards comp for y'all.
        if (data.length > 0 && !data.includes('{')) {
            data = JSON.stringify({"ops":[{"insert":`${data}\n`}]});
        }

        if (data.length === 0) {
            quill.setContents([{insert: data}]);
            getPlanLoad = false;
        } else if (data === JSON.stringify({"ops":[{"insert":"\n"}]})) {
            quill.setContents(data);
            getPlanLoad = false;
        } else if (data !== gotContents) {
            quill.setContents(data);
            quillTextChange(JSON.parse(data), JSON.parse(gotContents), null, true);
        }
    }

    // set Quill based on new client URL
    $: if (client !== $planGeneratorStore.plan.client) {
        (() => {
            setInitQuill();
            client = $planGeneratorStore.plan.client;
        })();
    }
</script>

<div class="field hide-print {isRiskManagementChecklist ? 'is-risk-management-quill' : ''}">
    <div id="editor-{typeId}" />
</div>
<div class="show-print ql-editor">
    <div id="show-print-editor-{typeId}" />
</div>

<style lang="stylus" scoped>@media screen and (max-width: 480px) {
  .is-risk-management-quill {
    height: 250px;
  }
}
@media screen and (max-width: 320px) {
  .is-risk-management-quill {
    height: 340px;
  }
}
</style>
